import { useEffect } from 'react';

export function useEffectWhen(
  when: () => boolean,
  callback: () => VoidFunction | undefined,
  depends: any[],
) {
  useEffect(() => {
    if (!when()) {
      return;
    }

    return callback();
  }, depends);
}
