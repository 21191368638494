import { Events } from 'make-event';

export class InputKeyboard {
  public static readonly onKeyUp = Events.make<KeyboardEvent>();
  public static readonly onKeyDown = Events.make<KeyboardEvent>();

  public static addListeners() {
    this.listenContextMenu();
    this.listenKeyUp();
    this.listenKeyDown();
  }

  private static listenContextMenu() {
    document.addEventListener('contextmenu', (event) => {
      event.preventDefault();
    });
  }

  private static listenKeyUp() {
    document.addEventListener('keyup', (event) => {
      this.onKeyUp.invoke(event);
    });
  }

  private static listenKeyDown() {
    document.addEventListener('keydown', (event) => {
      if (event.repeat) {
        return;
      }

      this.onKeyDown.invoke(event);
    });
  }
}
