import { useSchemaValue } from 'colyseus-schema-hooks';
import React, { useEffect, useMemo } from 'react';

import { Label } from './label';
import { usePlayer } from '../../hooks/use-player';

import type { SkillVariant } from '~/shared/battle/entity/unit/player/skill/types';

import { Section } from '~/client/core/ui/components/section';
import { useStateMap } from '~/client/core/ui/hooks/use-state-map';

export const SkillLabels: React.FC = () => {
  const player = usePlayer();

  const skills = useSchemaValue(player.schema, 'skills');
  const usingSkills = useStateMap<SkillVariant, boolean>();

  const list = useMemo(() => (
    Array.from(skills.entries()).sort(([, a], [, b]) => (
      b.type.localeCompare(a.type)
    )).map(([variant]) => variant)
  ), [skills]);

  useEffect(() => {
    const listeners: VoidFunction[] = [];
    skills.forEach((skill, variant) => {
      const unlisten = skill.listen('using', (using) => {
        usingSkills.set(variant, using);
      });
      listeners.push(unlisten);
    });

    return () => {
      listeners.forEach((unlisten) => {
        unlisten();
      });
    };
  }, []);

  return (
    <Section direction="horizontal" gap={4}>
      {list.map((variant) => (
        <Label
          key={variant}
          variant={variant}
          using={usingSkills.get(variant)}
        />
      ))}
    </Section>
  );
};
