import React from 'react';

import { Icon } from '~/client/core/ui/components/icon';

import styles from './styles.module.scss';

type Props = {
  icon: SVGIcon;
};

export const HintIcon: React.FC<Props> = ({ icon }) => (
  <div className={styles.frame}>
    <Icon type={icon} className={styles.icon} />
  </div>
);

