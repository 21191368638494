import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

import { InputKeyboard } from '~/client/core/input/keyboard';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRequest } from '~/client/core/request/ui/hooks/use-request';
import { useError } from '~/client/core/ui/components/error-plate/hooks/use-error';
import { Interactive } from '~/client/core/ui/components/interactive';
import { Section } from '~/client/core/ui/components/section';
import { useEvent } from '~/client/core/ui/hooks/use-event';
import { RelayRequest } from '~/shared/relay/types';

import styles from './styles.module.scss';

import IconJoin from './icons/join.svg';

export const WithFriend: React.FC = () => {
  const language = useLanguage();

  const joinFriendId = useRequest(RelayRequest.JoinFriendId);
  const generateFriendId = useRequest(RelayRequest.GenerateFriendId);
  const clearFriendId = useRequest(RelayRequest.ClearFriendId);

  const [id, setId] = useState('');
  const [yourId, setYourId] = useState('');
  const error = useError();

  const handleJoin = useCallback(() => {
    if (id) {
      if (joinFriendId.loading) {
        return;
      }

      joinFriendId.fetch({ id }).catch(() => {
        error.show();
      });
    } else {
      error.show();
    }
  }, [id]);

  const handleChangeId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setId(event.target.value);
    error.remove();
  };

  useEvent(InputKeyboard.onKeyDown, (event: KeyboardEvent) => {
    if (event.code === 'Enter') {
      handleJoin();
    }
  }, [handleJoin]);

  useEffect(() => {
    generateFriendId.fetch().then(setYourId);
    return () => {
      clearFriendId.fetch();
    };
  }, []);

  return (
    <Section direction="vertical" align="center" gap={20} className={styles.container}>
      <div className={styles.yourId}>
        {language('YourId')} <b>{yourId}</b>
      </div>
      <div className={cn(styles.form, {
        [styles.error]: error.active,
      })} >
        <input
          type="number"
          inputMode="numeric"
          placeholder={language('FriendId')}
          onChange={handleChangeId}
          value={id}
          maxLength={8}
        />
        <Interactive onClick={handleJoin} className={styles.button}>
          <IconJoin />
        </Interactive>
      </div>
    </Section>
  );
};
