import React from 'react';

import type { Relay } from '~/client/relay';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { SDK } from '~/client/core/sdk';
import { SDKAdType } from '~/client/core/sdk/types';
import { Crystals } from '~/client/core/ui/components/amount/crystals';
import { Icon } from '~/client/core/ui/components/icon';
import { Interactive } from '~/client/core/ui/components/interactive';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';
import { useUserData } from '~/client/relay/ui/hooks/use-user-data';
import { UserRequest } from '~/shared/core/user/types';
import { RELAY_ADS_REWARD } from '~/shared/relay/const';

import styles from './styles.module.scss';

import IconAds from './icons/ads.svg';

export const CrystalsReward: React.FC = () => {
  if (!SDK.enabled) {
    return null;
  }

  const relay = useRoom<Relay>();
  const adsAvailable = useUserData('adsAvailable');
  const language = useLanguage();

  if (!adsAvailable) {
    return null;
  }

  const handleClick = () => {
    SDK.showAd(SDKAdType.Rewarded).then((success) => {
      if (success) {
        relay.sendRequest(UserRequest.ApplyAdsReward);
      }
    });
  };

  return (
    <Interactive
      onClick={handleClick}
      className={styles.container}
    >
      <Icon type={IconAds} className={styles.icon} />
      <Section direction='horizontal' align='center' gap={12}>
        <Text size='m' uppercase>
          {language('Get')}
        </Text>
        <Crystals value={RELAY_ADS_REWARD} />
      </Section>
    </Interactive>
  );
};
