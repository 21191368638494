import React, { useMemo } from 'react';

import { Icon } from '~/client/core/ui/components/icon';
import { Interactive } from '~/client/core/ui/components/interactive';
import { USER_GOOGLE_AUTH_CLIENT_ID, USER_GOOGLE_AUTH_ROUTE_PATH } from '~/shared/core/user/auth/google/const';

import styles from './styles.module.scss';

import IconGoogle from './icons/google.svg';

export const GoogleButton: React.FC = () => {
  const link = useMemo(() => {
    const params = new URLSearchParams();
    params.set('client_id', USER_GOOGLE_AUTH_CLIENT_ID);
    params.set('redirect_uri', `${location.origin}${USER_GOOGLE_AUTH_ROUTE_PATH}`);
    params.set('scope', 'openid profile email');
    params.set('response_type', 'code');

    return `https://accounts.google.com/o/oauth2/v2/auth?${params.toString()}`;
  }, []);

  return (
    <Interactive className={styles.button} link={link}>
      <Icon type={IconGoogle} className={styles.icon} />
    </Interactive>
  );
};
