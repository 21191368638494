import cn from 'classnames';
import React, { useCallback, useState } from 'react';

import { Param } from '..';
import { Icon } from '../../../icon';
import { Interactive } from '../../../interactive';
import { Text } from '../../../text';
import { Option } from '../option';

import type { DictionaryPhrase } from '~/client/core/language/types';
import type { SettingsType } from '~/client/core/settings/types';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Settings } from '~/client/core/settings';

import styles from './styles.module.scss';

import IconOptions from './icons/options.svg';

type Props<T extends DictionaryPhrase> = {
  type: SettingsType;
  defaultValue: T;
  options: T[];
  save?: boolean;
  onChange: (value: T) => void;
};

export const ParamSelect = <T extends DictionaryPhrase>({
  type,
  defaultValue,
  options,
  save = true,
  onChange,
}: Props<T>) => {
  const language = useLanguage();

  const [value, setValue] = useState(defaultValue);
  const [opened, setOpened] = useState(false);

  const handleClick = useCallback(() => {
    setOpened(!opened);
  }, [opened]);

  const handleChange = (newValue: T) => {
    setOpened(false);
    setValue(newValue);
    onChange(newValue);

    if (save) {
      Settings.setEnum(type, newValue);
    }
  };

  return (
    <Param type={type}>
      <div className={styles.wrapper}>
        <Interactive className={cn(styles.value, {
          [styles.active]: opened,
        })} onClick={handleClick}>
          <Text size='l' bold uppercase>
            {language(value)}
          </Text>
          <Icon type={IconOptions} className={styles.icon} />
        </Interactive>
        {opened && (
          <div className={styles.options}>
            {options.map((option) => (
              <Option
                key={option}
                value={language(option)}
                active={value === option}
                onClick={() => handleChange(option)}
              />
            ))}
          </div>
        )}
      </div>
    </Param>
  );
};
