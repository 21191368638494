import React, { useCallback, useRef } from 'react';

import { useEventWhen } from '../../hooks/use-event-when';

import type { CSSProperties } from 'react';
import type { InputTouchChannel } from '~/client/core/input/touch/channel';

import { AudioType } from '~/client/core/audio/types';
import { useAudio } from '~/client/core/audio/ui/hooks/use-audio';
import { InputMouse } from '~/client/core/input/mouse';
import { InputTouch } from '~/client/core/input/touch';

type Props = {
  tag?: 'div' | 'button';
  children?: React.ReactNode;
  className?: string;
  onClick?: VoidFunction;
  disabled?: boolean;
  style?: CSSProperties;
  link?: string;
  withAudio?: boolean;
};

export const Interactive: React.FC<Props> = ({
  tag = 'div',
  link,
  children,
  className,
  disabled,
  withAudio,
  style,
  onClick,
}) => {
  const audio = useAudio();

  const ref = useRef<any>(null);

  const handleAction = useCallback(async () => {
    if (link) {
      const element = document.createElement('a');
      element.target = '_blank';
      element.href = link;
      element.click();
      element.remove();
    } else if (onClick) {
      onClick();
      if (withAudio) {
        audio.play(AudioType.Click);
      }
    }
  }, [link, onClick]);

  useEventWhen(InputTouch.onTouch, () => !disabled, (touch: InputTouchChannel) => {
    if (
      ref.current &&
      touch.targets.includes(ref.current)
    ) {
      touch.onRelease(() => {
        if (!touch.shifted) {
          handleAction();
        }
      });
    }
  }, [disabled, handleAction]);

  useEventWhen(InputMouse.onMouseClick, () => !disabled, (event: MouseEvent) => {
    if (
      ref.current &&
      event.composedPath().includes(ref.current)
    ) {
      handleAction();
    }
  }, [disabled, handleAction]);

  return tag === 'button' ? (
    <button ref={ref} className={className} style={style}>
      {children}
    </button>
  ) : (
    <div ref={ref} className={className} style={style}>
      {children}
    </div>
  );
};
