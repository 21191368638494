import { useEffect } from 'react';

import type { Entity } from '../..';

import { useEvent } from '~/client/core/ui/hooks/use-event';

export function useEntityUpdate(entity: Entity, callback: VoidFunction) {
  useEffect(callback, []);

  useEvent(entity.onUpdate, callback, []);
}
