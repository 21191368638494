import React, { useCallback } from 'react';

import { useRequest } from '~/client/core/request/ui/hooks/use-request';
import { Crystals } from '~/client/core/ui/components/amount/crystals';
import { Icon } from '~/client/core/ui/components/icon';
import { Interactive } from '~/client/core/ui/components/interactive';
import { useUserData } from '~/client/relay/ui/hooks/use-user-data';
import { SKILL_SLOT_COST } from '~/shared/battle/entity/unit/player/skill/const';
import { InventoryRequest } from '~/shared/core/inventory/types';

import styles from './styles.module.scss';

import IconLock from './icons/lock.svg';

export const SlotBuy: React.FC = () => {
  const buySlot = useRequest(InventoryRequest.BuySlot);

  const crystals = useUserData('crystals');
  const allow = crystals >= SKILL_SLOT_COST;

  const handleClick = useCallback(() => {
    buySlot.fetch();
  }, []);

  return (
    <Interactive
      disabled={!allow || buySlot.loading}
      onClick={handleClick}
      className={styles.frame}
    >
      <Icon type={IconLock} className={styles.lock} />
      <Crystals value={SKILL_SLOT_COST} />
    </Interactive>
  );
};
