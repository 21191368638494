import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import { ButtonLogout } from './button-logout';
import { Name } from './name';
import { useUserData } from '../../../hooks/use-user-data';
import { Sheet } from '../../sheet';

import type { Relay } from '../../../..';
import type { UserStatistic } from '~/shared/core/user/types';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Crystals } from '~/client/core/ui/components/amount/crystals';
import { Score } from '~/client/core/ui/components/amount/score';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';
import { ValueCard } from '~/client/core/ui/components/value-card';
import { UserRequest } from '~/shared/core/user/types';

import styles from './styles.module.scss';

export const PageProfile: React.FC = () => {
  const room = useRoom<Relay>();
  const language = useLanguage();

  const crystals = useUserData('crystals');
  const score = useUserData('score');

  const [statistic, setStatistic] = useState<Nullable<UserStatistic>>(null);

  useEffect(() => {
    room
      .sendRequest<UserStatistic>(UserRequest.GetStatistic)
      .then(setStatistic);
  }, []);

  return (
    <Sheet>
      <Section direction='vertical' align='center' gap={64}>
        <Name />
        <Section direction='vertical' gap={32}>
          <div className={cn(styles.grid, styles.two)}>
            <ValueCard label={language('Crystals')} size='l'>
              <Crystals value={crystals} size='l' />
            </ValueCard>
            <ValueCard label={language('Experience')} size='l'>
              <Score value={score} size='l' />
            </ValueCard>
          </div>
          <div className={cn(styles.grid, styles.three)}>
            <ValueCard view='light' label={language('Place')} size='m'>
              <Text size='l' bold>
                {statistic?.place ?? 1}
              </Text>
            </ValueCard>
            <ValueCard view='light' label={language('MaxWave')} size='m'>
              <Text size='l' bold>
                {statistic?.maxWave ?? 0}
              </Text>
            </ValueCard>
            <ValueCard view='light' label={language('TotalWaves')} size='m'>
              <Text size='l' bold>
                {statistic?.totalWaves ?? 0}
              </Text>
            </ValueCard>
          </div>
        </Section>
        <ButtonLogout />
      </Section>
    </Sheet>
  );
};
