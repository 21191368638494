import { Events } from 'make-event';
import { Vector3, Raycaster, PlaneGeometry, Mesh } from 'three';

import { BuildArea } from './build-area';
import { BuildingPreview } from './building-preview';

import type { Battle } from '..';
import type { Vector2, Vector3Like } from 'three';
import type { InputTouchChannel } from '~/client/core/input/touch/channel';
import type { Model } from '~/client/core/render-item/model';
import type { BuilderMessagePayload } from '~/shared/battle/builder/types';

import { MaterialType } from '~/client/core/assets/materials/types';
import { AudioType } from '~/client/core/audio/types';
import { Device } from '~/client/core/device';
import { InputMouse } from '~/client/core/input/mouse';
import { InputTouch } from '~/client/core/input/touch';
import { Logger } from '~/client/core/logger';
import { Messages } from '~/client/core/messages';
import { BuilderShared } from '~/shared/battle/builder';
import { BuilderMessage } from '~/shared/battle/builder/types';
import { BUILDINGS_SHARED_CONFIG } from '~/shared/battle/entity/building/const';
import { BuildingVariant } from '~/shared/battle/entity/building/types';
import { TERRAIN_SIZE } from '~/shared/battle/terrain/const';
import { VectorUtils } from '~/shared/core/vector-utils';

import './resources';

export class Builder extends BuilderShared {
  private readonly battle: Battle;

  public previewBuilding: Nullable<Model> = null;

  private buildArea: Nullable<BuildArea> = null;

  private readonly cursor: Vector3 = new Vector3();

  public variant: Nullable<BuildingVariant> = null;

  private readonly messages: Messages<BuilderMessagePayload>;

  public readonly onToggleBuild = Events.make<boolean>();
  public readonly onChangeValidState = Events.make<boolean>();
  public readonly onChangeVariant = Events.make<Nullable<BuildingVariant>>();

  private readonly raycaster: Raycaster = new Raycaster();

  public validState: boolean = false;

  private groundHelper: Mesh;

  constructor(battle: Battle) {
    super(battle.state);

    this.battle = battle;
    this.messages = new Messages(this.battle.origin, this.battle.messagesBuffer);

    this.createGroundHelper();

    this.onSceneUpdate = this.onSceneUpdate.bind(this);
    this.onMouseMoveWorld = this.onMouseMoveWorld.bind(this);
    this.onMouseClickWorld = this.onMouseClickWorld.bind(this);
    this.onTouchWorld = this.onTouchWorld.bind(this);

    this.onBattleReset = this.onBattleReset.bind(this);
    this.battle.onFinish(this.onBattleReset);

    this.onBattlePreparing = this.onBattlePreparing.bind(this);
    this.battle.onPreparing(this.onBattlePreparing);
  }

  public destroy(): void {
    this.removeBuildArea();
    this.removePreview();

    this.battle.scene.onUpdate.unsubscribe(this.onSceneUpdate);
    this.battle.onFinish.unsubscribe(this.onBattleReset);
    this.battle.onPreparing.unsubscribe(this.onBattlePreparing);

    InputMouse.onMouseMoveWorld.unsubscribe(this.onMouseMoveWorld);
    InputMouse.onMouseClickWorld.unsubscribe(this.onMouseClickWorld);
    InputTouch.onTouchWorld.unsubscribe(this.onTouchWorld);

    this.onToggleBuild.clear();
    this.onChangeValidState.clear();
    this.onChangeVariant.clear();
  }

  private onSceneUpdate() {
    this.updateBuildState();
  }

  private onBattleReset() {
    this.setVariant(null);
  }

  private onBattlePreparing() {
    this.setVariant(null);
  }

  public getAvailableBuildings() {
    const playerSchema = this.battle.getSelfPlayerSchema();
    return Object.values(BuildingVariant).filter((variant) => (
      this.isBuildingEnabled(variant) &&
      !this.isLimitExceeded(variant, playerSchema) &&
      !this.isRestricted(variant, playerSchema)
    ));
  }

  public isBuild() {
    return Boolean(this.variant);
  }

  public isBuildingEnabled(variant: BuildingVariant) {
    const { mode } = BUILDINGS_SHARED_CONFIG[variant];
    return !mode || mode === this.battle.state.mode;
  }

  public setVariant(variant: Nullable<BuildingVariant>) {
    const prevVariant = this.variant;

    this.variant = variant;

    if (this.variant) {
      if (prevVariant) {
        this.removePreview();
        this.createPreview();
      } else {
        this.createBuildArea();
        this.createPreview();

        InputTouch.onTouchWorld(this.onTouchWorld);
        InputMouse.onMouseMoveWorld(this.onMouseMoveWorld);
        InputMouse.onMouseClickWorld(this.onMouseClickWorld);

        this.battle.scene.onUpdate(this.onSceneUpdate);
        this.onToggleBuild.invoke(true);

        if (this.battle.scene.selectedBuilding) {
          this.battle.scene.selectedBuilding.clickOutside();
          this.battle.scene.selectedBuilding = null;
        }
      }

      this.updateBuildState(true);
    } else if (prevVariant) {
      this.removeBuildArea();
      this.removePreview();

      InputTouch.onTouchWorld.unsubscribe(this.onTouchWorld);
      InputMouse.onMouseMoveWorld.unsubscribe(this.onMouseMoveWorld);
      InputMouse.onMouseClickWorld.unsubscribe(this.onMouseClickWorld);

      this.battle.scene.onUpdate.unsubscribe(this.onSceneUpdate);
      this.onToggleBuild.invoke(false);
    }

    this.onChangeVariant.invoke(variant);
  }

  private createPreview() {
    if (this.previewBuilding) {
      Logger.warn('Builder preview is already created');
      return;
    }

    if (!this.variant) {
      Logger.warn('Unable to create builder preview with unknown variant');
      return;
    }

    this.previewBuilding = new BuildingPreview(this.battle, this.variant);

    if (Device.isMobile) {
      const player = this.battle.getSelfPlayer();
      this.setPosition(
        player.renderItem.position.clone().round().add({ x: 1, y: 0, z: 1 }),
      );
    } else {
      this.updatePreviewPosition(InputMouse.normalizedPosition);
    }
  }

  private updatePreviewPosition(position: Vector2) {
    if (!this.previewBuilding) {
      return;
    }

    this.raycaster.setFromCamera(position, this.battle.scene.camera);
    const [intersect] = this.raycaster.intersectObject(this.groundHelper);
    if (intersect) {
      this.setPosition(
        intersect.point.round(),
      );
    }
  }

  private setPosition(position: Vector3Like) {
    if (!this.previewBuilding) {
      return;
    }

    this.cursor.copy(position);
    this.previewBuilding.setPosition(this.cursor);
  }

  private removePreview() {
    if (!this.previewBuilding) {
      return;
    }

    this.previewBuilding.destroy();
    this.previewBuilding = null;
  }

  public build() {
    if (!this.variant) {
      return;
    }

    if (!this.validState) {
      this.battle.scene.audio.play2D(AudioType.Error);
      return;
    }

    this.battle.scene.audio.play2D(AudioType.Build);

    this.messages.send(BuilderMessage.Build, {
      variant: this.variant,
      position: this.cursor,
    });

    this.setVariant(null);
  }

  private createBuildArea() {
    if (this.buildArea) {
      Logger.warn('Builder area is already created');
      return;
    }

    const player = this.battle.getSelfPlayer();
    this.buildArea = new BuildArea(player);
  }

  private removeBuildArea() {
    if (!this.buildArea) {
      return;
    }

    this.buildArea.destroy();
    this.buildArea = null;
  }

  private updateBuildState(force?: boolean) {
    if (!this.variant) {
      return;
    }

    const playerSchema = this.battle.getSelfPlayerSchema();
    const validState = (
      this.isPositionInsideRadius(this.cursor, playerSchema) &&
      !this.isPositionTouchUnit(this.cursor) &&
      !this.battle.state.terrain.matrix.has(VectorUtils.encode2d(this.cursor))
    );

    if (force || this.validState !== validState) {
      this.validState = validState;
      this.previewBuilding?.setMaterial(
        validState ? MaterialType.Building : MaterialType.BuildingInvalid,
      );

      this.onChangeValidState.invoke(validState);
    }
  }

  private onTouchWorld(touch: InputTouchChannel) {
    touch.onMove(() => {
      const position = touch.normalizedPosition.clone().add({ x: 0, y: 0.2 });
      this.updatePreviewPosition(position);
    });
  }

  private onMouseMoveWorld() {
    this.updatePreviewPosition(InputMouse.normalizedPosition);
  }

  private onMouseClickWorld(event: MouseEvent) {
    if (event.button === 0) {
      this.build();
    } else if (event.button === 2) {
      this.setVariant(null);
    }
  }

  private createGroundHelper() {
    this.groundHelper = new Mesh(
      new PlaneGeometry(TERRAIN_SIZE.x * 2, TERRAIN_SIZE.z * 2),
    );

    this.groundHelper.visible = false;
    this.groundHelper.rotateX(-0.5 * Math.PI);
    this.groundHelper.position.set(TERRAIN_SIZE.x, 1.0, TERRAIN_SIZE.z);

    this.battle.scene.add(this.groundHelper);
  }
}
