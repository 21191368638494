import { BUILDING_MODELS, BUILDING_MODEL_UPGRADE_PREFIX } from '../../entity/building/const';
import { BattleSceneLayer } from '../../scene/types';

import type { Battle } from '../..';
import type { BuildingVariant } from '~/shared/battle/entity/building/types';

import { MaterialType } from '~/client/core/assets/materials/types';
import { Logger } from '~/client/core/logger';
import { Model } from '~/client/core/render-item/model';

export class BuildingPreview extends Model {
  constructor(battle: Battle, variant: BuildingVariant) {
    super(battle.scene, {
      model: BUILDING_MODELS[variant],
      material: MaterialType.Building,
    });

    this.eachMeshes((mesh) => {
      mesh.layers.set(BattleSceneLayer.Misc);
    });

    const root = this.getRoot();
    if (!root) {
      Logger.warn(`Invalid root node of model for building '${variant}'`);
      return;
    }

    root.children.forEach((object) => {
      if (object.name.indexOf(BUILDING_MODEL_UPGRADE_PREFIX) === 0) {
        object.visible = false;
      }
    });
  }
}
