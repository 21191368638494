import React from 'react';

import { InventoryItem } from './item';
import { INVENTORY_CATEGORY_ITEMS } from '../../const';
import { useInventoryItems } from '../../hooks/use-inventory-items';

import type { DroidVariant } from '~/shared/battle/entity/unit/npc/droid/types';

import { InventoryItemType } from '~/shared/core/inventory/item/types';

import styles from './styles.module.scss';

export const InventoryTabDroid: React.FC = () => {
  const currentItems = useInventoryItems();

  const items = INVENTORY_CATEGORY_ITEMS[InventoryItemType.Droid].map((item) => ({
    item,
    have: currentItems.has(item),
  }));

  return (
    <div className={styles.grid}>
      {items.map(({ item, have }) => (
        <InventoryItem key={item} item={item as DroidVariant} have={have} />
      ))}
    </div>
  );
};
