import cn from 'classnames';
import { useSchemaValue } from 'colyseus-schema-hooks';
import React, { useCallback } from 'react';

import { SkillInfo } from './info';
import { SKILL_ICON } from '../../skill-icon/const';

import type { Battle } from '~/client/battle';
import type { SkillVariant, SkillSchema } from '~/shared/battle/entity/unit/player/skill/types';

import { useSelfPlayer } from '~/client/battle/ui/hooks/use-self-player';
import { AudioType } from '~/client/core/audio/types';
import { useAudio } from '~/client/core/audio/ui/hooks/use-audio';
import { Device } from '~/client/core/device';
import { InputKeyboard } from '~/client/core/input/keyboard';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Icon } from '~/client/core/ui/components/icon';
import { Interactive } from '~/client/core/ui/components/interactive';
import { useEvent } from '~/client/core/ui/hooks/use-event';
import { SkillType } from '~/shared/battle/entity/unit/player/skill/types';

import styles from './styles.module.scss';

type Props = {
  variant: SkillVariant;
  skill: SkillSchema;
  index: number;
};

export const SkillSlot: React.FC<Props> = ({ variant, index, skill }) => {
  const battle = useRoom<Battle>();
  const audio = useAudio();
  const player = useSelfPlayer();

  const recoveryLeft = skill ? useSchemaValue(skill, 'recoveryLeft') : 0;

  const angle = (90 / (player.schema.skills.size - 1)) * index - 45;
  const usable = recoveryLeft === 0 && skill.type === SkillType.Active;

  const handleClick = useCallback(() => {
    audio.play(AudioType.PlayerUseSkill);
    player.useSkill(variant);
  }, []);

  useEvent(InputKeyboard.onKeyDown, (event: KeyboardEvent) => {
    if (battle.state.paused || !usable) {
      return;
    }

    const key = Number(event.key);
    if (key === index + 1) {
      handleClick();
    }
  }, [usable, handleClick]);

  return (
    <Interactive
      onClick={handleClick}
      disabled={!usable}
      className={cn(
        styles.container,
        styles[skill.type.toLowerCase()], {
          [styles.usable]: usable,
        },
      )}
      style={Device.isMobile ? {
        transform: `rotate(${angle}deg) translate(-130px,-130px) rotate(${-angle}deg)`,
      } : undefined}
    >
      {Device.isDesktop && (
        <SkillInfo variant={variant} skill={skill} />
      )}
      {recoveryLeft > 0 && (
        <div
          className={styles.timeout}
          style={{
            '--progress': `${(1 - (recoveryLeft / skill.recovery)) * 360}deg`,
          }}
        />
      )}
      <Icon type={SKILL_ICON[variant]} className={styles.icon} />
      {Device.isDesktop && (
        <div className={styles.hotkey}>
          {usable ? index + 1 : '·'}
        </div>
      )}
    </Interactive>
  );
};

