import React from 'react';

import { Amount } from '..';

import IconCrystal from './icons/crystal.svg';

type Props = {
  value: number;
  size?: 's' | 'm' | 'l';
  animated?: boolean;
};

export const Crystals: React.FC<Props> = ({ value, size, animated }) => (
  <Amount
    icon={IconCrystal}
    color="#85caff"
    value={value}
    size={size}
    animated={animated}
  />
);
