import { Crystal } from './crystal';
import { Decoration } from './decoration';
import { Fog } from './fog';
import { Tile } from './tile';
import { BattleSceneLayer } from '../scene/types';

import type { Battle } from '..';
import type { ModelType } from '~/client/core/assets/types';
import type { DecorationSchema } from '~/shared/battle/terrain/decoration/types';
import type { TileSchema } from '~/shared/battle/terrain/tile/types';

import { Assets } from '~/client/core/assets';
import { MaterialType } from '~/client/core/assets/materials/types';
import { AudioType } from '~/client/core/audio/types';
import { MeshSet } from '~/client/core/render-item/mesh-set';
import { TILE_OFFSET } from '~/shared/battle/terrain/tile/const';
import { TerrainBiomeType } from '~/shared/battle/terrain/types';
import { CubeGeometry } from '~/shared/core/geometry/cube';
import { ArrayUtils } from '~/shared/core/utils/array';

import './resources';

export class Terrain {
  public readonly battle: Battle;

  public readonly fog: Fog;

  public readonly crystals: Set<Crystal> = new Set();

  private readonly decorationsSet: Map<ModelType, MeshSet> = new Map();

  private readonly tilesSet: Map<string, MeshSet> = new Map();

  constructor(battle: Battle) {
    this.battle = battle;

    this.fog = new Fog(this.battle);

    // Need to wait the updating of terrain maps to create meshes.
    // Crystals may be created only in this event, because they have a disabled flag 'tiggerAll'.
    // With the enabled flag we have a schema bug with duplicate calls of 'onAdd' event.
    this.battle.state.terrain.onChange(() => {
      this.createTiles();
      this.createDecorations();
      this.createCrystals();
    });

    this.onBattlePreparing = this.onBattlePreparing.bind(this);
    this.battle.onPreparing(this.onBattlePreparing);
  }

  public destroy(): void {
    this.fog.destroy();
    this.destroyMeshes();

    this.battle.onPreparing.unsubscribe(this.onBattlePreparing);
  }

  private onBattlePreparing(): void {
    // Recreate terrain meshes on battle preparing after restart.
    this.destroyMeshes();
  }

  private createCrystals() {
    this.battle.state.terrain.crystals.onAdd((crystal) => {
      new Crystal(this.battle, crystal);
    }, false);
  }

  private createDecorations() {
    const decorations = Array.from(this.battle.state.terrain.decorations.values());
    const groups = ArrayUtils.group(decorations, Decoration.getModel);

    groups.forEach((schemas, model) => {
      this.createDecorationsMeshSet(model, schemas);
    });
  }

  private createDecorationsMeshSet(model: ModelType, decorations: DecorationSchema[]) {
    const material = Assets.getMaterial(MaterialType.Decoration);
    const geometry = Assets.getModelGeometry(model);
    if (!geometry) {
      throw Error(`Undefined geometry of decoration '${model}'`);
    }

    const children = decorations.map((schema) => (
      new Decoration(schema)
    ));

    const meshSet = new MeshSet(this.battle.scene, {
      geometry,
      material,
      children,
    });

    meshSet.layers.set(BattleSceneLayer.Misc);
    this.decorationsSet.set(model, meshSet);
  }

  private createTiles() {
    const tiles = Array.from(this.battle.state.terrain.tiles.values());
    const groups = ArrayUtils.group(tiles, Tile.getGroupKey);

    groups.forEach((schemas, key) => {
      this.createTilesMeshSet(key, schemas);
    });
  }

  private createTilesMeshSet(key: string, tiles: TileSchema[]) {
    // Every tile of set has the same biome, index and visible indexes.
    const { biome, index, visibleIndexes } = tiles[0];

    const material = Assets.getMaterial(`${biome}${index}` as MaterialType);
    const geometry = CubeGeometry.make(visibleIndexes);

    const children = tiles.map((schema) => (
      new Tile(schema)
    ));

    const meshSet = new MeshSet(this.battle.scene, {
      geometry,
      material,
      children,
    });

    meshSet.name = biome;
    meshSet.position.setY(TILE_OFFSET[biome]);

    switch (biome) {
      case TerrainBiomeType.Liquid: {
        meshSet.layers.set(BattleSceneLayer.Liquid);
        break;
      }
      case TerrainBiomeType.Mounts: {
        meshSet.layers.set(BattleSceneLayer.Mounts);
        break;
      }
      default: {
        meshSet.layers.set(BattleSceneLayer.Ground);
        break;
      }
    }

    this.tilesSet.set(key, meshSet);
  }

  private destroyMeshes(): void {
    this.crystals.forEach((crystal) => {
      crystal.destroy();
    });
    this.crystals.clear();

    this.decorationsSet.forEach((meshSet) => {
      meshSet.destroy();
    });
    this.decorationsSet.clear();

    this.tilesSet.forEach((meshSet) => {
      meshSet.geometry.dispose();
      meshSet.destroy();
    });
    this.tilesSet.clear();
  }

  public toggleAmbientAudio(state: boolean) {
    if (state) {
      this.battle.scene.audio.play2D(AudioType.Ambient);
    } else {
      this.battle.scene.audio.stop(AudioType.Ambient);
    }
  }
}
