import { useState } from 'react';

import { useEventWhen } from './use-event-when';
import { InputMouse } from '../../input/mouse';
import { InputTouch } from '../../input/touch';

import type { InputTouchChannel } from '../../input/touch/channel';
import type { RefObject } from 'react';

type Props = {
  active?: boolean;
  target: RefObject<HTMLDivElement>;
  control: RefObject<HTMLDivElement>;
  onStart?: VoidFunction;
  onDrag: (distance: number) => void
};

export function useDraggable({ active = false, target, control, onStart, onDrag }: Props) {
  const [dragging, setDragging] = useState(false);

  useEventWhen(InputTouch.onTouch, () => active, (touch: InputTouchChannel) => {
    if (!target.current || !touch.targets.includes(target.current)) {
      return;
    }

    onStart?.();
    setDragging(true);

    touch.takeUp();

    touch.onMove(() => {
      const distance = touch.beginPosition.y - touch.position.y;
      onDrag(distance);
    });

    touch.onRelease(() => {
      setDragging(false);
    });
  }, [active]);

  useEventWhen(InputMouse.onMouseClick, () => active, (event: MouseEvent) => {
    if (!control.current || !event.composedPath().includes(control.current)) {
      return;
    }

    onStart?.();
    setDragging(true);

    const beginPosition = InputMouse.position.y;

    const unsubscribeMouseMove = InputMouse.onMouseMove(() => {
      const distance = InputMouse.position.y - beginPosition;
      onDrag(distance);
    });

    const unsubscribeMouseRelease = InputMouse.onMouseRelease(() => {
      unsubscribeMouseMove();
      unsubscribeMouseRelease();
      setDragging(false);
    });
  }, [active]);

  // useEffect(() => {
  //   if (!dragging || Device.isMobile) {
  //     return;
  //   }

  //   return () => {
  //     refMouseMove.current?.off();
  //     refMouseMove.current = null;
  //     refMouseRelease.current?.off();
  //     refMouseRelease.current = null;
  //   };
  // }, [dragging]);

  return { dragging };
}
