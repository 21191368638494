import cn from 'classnames';
import React from 'react';

import type { Tab } from './types';

import { Interactive } from '~/client/core/ui/components/interactive';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';

import styles from './styles.module.scss';

type Props = {
  tabs: Tab[];
  active: string;
  size?: 'm' | 'l';
  onChange: (tab: string) => void;
};

export const Tabs: React.FC<Props> = ({ tabs, active, size = 'm', onChange }) => (
  <Section direction="horizontal" align="center" gap={32}>
    {tabs.map(({ key, label }) => (
      <Interactive
        key={key}
        onClick={() => onChange(key)}
        disabled={key === active}
      >
        <Text
          size={size === 'l' ? '2xl' : 'xl'}
          bold
          uppercase
          className={cn(styles.tab, styles[`size-${size}`], {
            [styles.active]: key === active,
          })}
        >
          {label}
        </Text>
      </Interactive>
    ))}
  </Section>
);

