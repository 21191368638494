import { useEffectWhen } from './use-effect-when';

import type { Event } from 'make-event';

export function useEventWhen<T>(
  event: Event<T>,
  when: () => boolean,
  callback: (payload: T) => void,
  deps: any[],
) {
  useEffectWhen(when, () => {
    const unsubscribe = event(callback);
    return () => {
      unsubscribe();
    };
  }, deps);
}
