import { PositionalAudio } from 'three';

import { AUDIO_MAX_DISTANCE } from './const';
import { AudioTrack } from '..';
import { AUDIO_VOLUME } from '../../const';

import type { AudioTrack3DConfig, AudioTrack3DPlayData } from './types';
import type { AudioType } from '../../types';
import type { AudioListener, Vector3Like } from 'three';

import { Logger } from '~/client/core/logger';
import { ArrayUtils } from '~/shared/core/utils/array';

export class AudioTrack3D extends AudioTrack {
  private readonly playings: Set<PositionalAudio> = new Set();

  private readonly pool: Set<PositionalAudio> = new Set();

  private readonly buffers: AudioBuffer[] = [];

  private readonly volume: number;

  constructor(type: AudioType, listener: AudioListener, {
    buffers,
    limit = 1,
    volume = 1.0,
    loop = false,
  }: AudioTrack3DConfig) {
    super(type, listener);

    this.buffers = buffers;
    this.volume = AUDIO_VOLUME * volume;

    for (let i = 0; i < limit; i++) {
      const audio = new PositionalAudio(listener);
      audio.setVolume(this.volume);
      audio.loop = loop ?? false;

      this.pool.add(audio);
    }
  }

  public destroy() {
    this.playings.forEach((audio) => {
      audio.onEnded();
      audio.stop();
    });
    this.playings.clear();
  }

  public play({ scene, position }: AudioTrack3DPlayData) {
    if (!this.isNormalDistance(position)) {
      return;
    }

    const buffer = ArrayUtils.getRandom(this.buffers);
    if (!buffer) {
      Logger.warn('Unable to get buffer of 3D audio track');
      return;
    }

    const audio = this.topFromPool();
    if (!audio) {
      return;
    }

    audio.setBuffer(buffer);
    if (position) {
      audio.position.copy(position);
    }

    this.playings.add(audio);
    scene.add(audio);

    audio.onEnded = () => {
      this.playings.delete(audio);
      audio.removeFromParent();

      this.returnToPool(audio);
      audio.isPlaying = false;

      // @ts-ignore
      delete audio.onEnded; // ?
    };

    audio.isPlaying = false;
    audio.play();
  }

  public stop() {
    this.playings.forEach((audio) => {
      audio.onEnded();
      audio.stop();
    });
  }

  public disable() {
    this.pool.forEach((audio) => {
      audio.setVolume(0);
    });
  }

  public enable() {
    this.pool.forEach((audio) => {
      audio.setVolume(this.volume);
    });
  }

  private isNormalDistance(position: Vector3Like) {
    if (!this.listener.parent) {
      return;
    }

    const distance = this.listener.parent.position.distanceTo(position);
    return distance <= AUDIO_MAX_DISTANCE;
  }

  private topFromPool(): Nullable<PositionalAudio> {
    const audio = this.pool.values().next().value ?? null;
    if (audio) {
      this.pool.delete(audio);
    }

    return audio;
  }

  private returnToPool(audio: PositionalAudio) {
    this.pool.add(audio);
  }
}
