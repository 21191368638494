import { PerspectiveCamera, Matrix4, Vector3, Vector2 } from 'three';

import { CAMERA_FOV, CAMERA_SHIFT_MULTIPLIER, CAMERA_TARGET_SCREEN_HEIGHT } from './const';

import type { Vector2Like, Vector3Like } from 'three';
import type { RenderItem } from '~/client/core/render-item';

import { Device } from '~/client/core/device';

export class Camera extends PerspectiveCamera {
  private target: Nullable<RenderItem> = null;

  private targetOffset: Vector3 = new Vector3();

  private angle: number = 0;

  private rotationMatrix: Matrix4 = new Matrix4();

  private distance: number = 0;

  constructor() {
    super(CAMERA_FOV);

    this.layers.enableAll();
  }

  public setSize(size: Vector2Like): void {
    this.aspect = size.x / size.y;
    this.updateProjectionMatrix();
  }

  public setTarget(target: RenderItem): void {
    this.target = target;
  }

  public setTargetOffset(offset: number): void {
    this.targetOffset.copy({
      x: offset,
      y: 0,
      z: offset,
    });
  }

  public setAngle(angle: number): void {
    this.angle = angle;
    this.rotationMatrix = new Matrix4().makeRotationY(-angle);
  }

  public setDistance(distance: number): void {
    this.distance = distance;
  }

  public update(): void {
    if (!this.target) {
      return;
    }

    const screenSize = Device.getScreenSize();
    const zoom = Math.sqrt(screenSize.y / CAMERA_TARGET_SCREEN_HEIGHT);
    const distance = this.distance * Math.min(1.0, zoom);
    this.position
      .copy(this.target.position)
      .add({
        x: distance * Math.sin(this.angle),
        y: distance,
        z: distance * Math.cos(this.angle),
      });

    const targetPosition = this.target.position.clone().add(this.targetOffset);
    this.lookAt(targetPosition);
  }

  public getScreenPosition(worldPosition: Vector3Like): Vector3 {
    const screenSize = Device.getScreenSize();
    const halfSize = new Vector2().copy(screenSize).divideScalar(2);
    const position = new Vector3().copy(worldPosition);

    position.project(this);
    position.x = (position.x * halfSize.x) + halfSize.x;
    position.y = -(position.y * halfSize.y) + halfSize.y;

    const shift = this.getShift(worldPosition);
    position.x += shift.x;
    position.y += shift.z;

    return position;
  }

  private getShift(position: Vector3Like) {
    const multiplier = (1 / this.distance) * CAMERA_SHIFT_MULTIPLIER;
    return new Vector3()
      .copy(position)
      .sub(this.target?.position ?? this.position)
      .applyMatrix4(this.rotationMatrix)
      .multiplyScalar(multiplier);
  }
}
