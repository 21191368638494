import { PageType } from '../../../relay/ui/components/pages/types';
import { SettingsType } from '../../settings/types';
import { LanguageType } from '../types';

import { ParameterType } from '~/client/battle/entity/unit/player/ui/components/info-panel/parameter/types';
import { KeyAction } from '~/client/battle/tutorial/ui/components/hints/content/key/types';
import { SwipeDirection } from '~/client/battle/tutorial/ui/components/hints/content/swipe/types';
import { BuildingCategory, BuildingVariant } from '~/shared/battle/entity/building/types';
import { DroidVariant } from '~/shared/battle/entity/unit/npc/droid/types';
import { SkillVariant } from '~/shared/battle/entity/unit/player/skill/types';
import { UpgradeCategory, UpgradeVariant } from '~/shared/battle/entity/unit/player/upgrades/types';
import { TutorialStep } from '~/shared/battle/tutorial/types';
import { BattleDifficult } from '~/shared/battle/types';
import { InventoryItemType } from '~/shared/core/inventory/item/types';
import { UserError } from '~/shared/core/user/types';

const PHRASES = {
  [PageType.Play]: 'Jogar',
  [PageType.Inventory]: 'Inventário',
  [PageType.Leaderboard]: 'Líderes',
  [PageType.Profile]: 'Perfil',

  LeaveAccount: 'Sair da conta',
  ToLogIn: 'Entrar',
  ToSignUp: 'Cadastrar',
  LogIn: 'Entrada',
  SignUp: 'Cadastro',
  Username: 'Nome de utilizador',
  Password: 'Palavra-passe',
  AcceptTerms: 'Eu aceito os termos da',
  PrivacyPolicy: 'política de privacidade',

  [UserError.AlreadyAuthorized]: 'Já está autorizado',
  [UserError.ExistUsername]: 'O nome de utilizador especificado já está em uso',
  [UserError.InvalidToken]: 'Token de autorização inválido',
  [UserError.InvalidCredentials]: 'Nome de utilizador ou palavra-passe incorretos',
  [UserError.InvalidUsername]: 'Nome de utilizador inválido',
  [UserError.InvalidPassword]: 'Palavra-passe inválida',

  MainMenu: 'Menu principal',
  Continue: 'Continuar',
  Restart: 'Reiniciar',
  Settings: 'Configurações',
  RestartConfirm: 'Você tem certeza que quer começar de novo?',
  LeaveConfirm: 'Você tem certeza que quer sair desta batalha?',
  Back: 'Voltar',

  Place: 'Lugar',
  MaxWave: 'Onda máx',
  TotalWaves: 'Total de ondas',

  AuthTo: 'Faça login para',
  SaveReward: 'Salvar a recompensa',
  LoadSave: 'Carregar o jogo salvo',
  PlayAgain: 'Jogar de novo',
  WaitingOpponent: 'À espera do oponente',
  OpponentDisconnected: 'O oponente abandonou a batalha',

  YouWin: 'Você venceu',
  YouLose: 'Você perdeu',
  YouDied: 'Você morreu',
  BaseDestroyed: 'Base destruída',

  [`Difficult${BattleDifficult.Tutorial}`]: 'Tutorial',
  [`Difficult${BattleDifficult.Tutorial}Description`]: 'Para o primeiro jogo',
  [`Difficult${BattleDifficult.Normal}`]: 'Normal',
  [`Difficult${BattleDifficult.Normal}Description`]: 'Para iniciantes',
  [`Difficult${BattleDifficult.Hard}`]: 'Difícil',
  [`Difficult${BattleDifficult.Hard}Description`]: 'Para jogadores experientes',

  PlayerOnline: 'Jogador online',
  PlayersOnline: 'Jogadores online',

  YouRanked: 'Está classificado',
  PlaceOf: 'lugar de',

  Earn: 'Ganhe',
  UnlockOnlineModes: 'para desbloquear os modos online',

  SearchingOpponent: 'À procura de\num oponente',
  PlaySingle: 'Jogo individual',
  PlaySingleDescription: 'Jogue no modo de ondas infinitas',
  PlayOnline: 'Jogo online',
  PlayOnlineDescription: 'Jogue com um oponente aleatório',
  PlayOnlinePrivate: 'Jogo privado',
  PlayOnlinePrivateDescription: 'Jogue com um amigo',
  YourId: 'O seu ID',
  FriendId: 'ID do amigo',

  OrHold: 'Ou segure',

  Yes: 'Sim',
  No: 'Não',

  On: 'Ligado',
  Off: 'Desligado',

  Low: 'Baixo',
  Medium: 'Médio',
  High: 'Alto',

  To: 'para',

  [KeyAction.Press]: 'Pressione',
  [KeyAction.Hold]: 'Segure',
  [`Swipe${SwipeDirection.Up}`]: 'Deslize para cima',
  [`Swipe${SwipeDirection.Left}`]: 'Deslize à esquerda',
  [`Hint${TutorialStep.Attack}`]: 'ataque os inimigos',
  [`Hint${TutorialStep.UpgradeGenerator}`]: 'Toque no gerador\npara melhorá-lo',
  [`Hint${TutorialStep.UpgradeTowerFire}`]: 'Toque na torre\npara melhorá-la',
  [`Hint${TutorialStep.UpgradePlayer}`]: 'melhorar o personagem',
  [`Hint${TutorialStep.BuildGenerator}`]: 'construir gerador',
  [`Hint${TutorialStep.BuildSecondGenerator}`]: 'construir o segundo gerador',
  [`Hint${TutorialStep.BuildAmmunition}`]: 'construir munição',
  [`Hint${TutorialStep.BuildSecondAmmunition}`]: 'construir o segundo munição',
  [`Hint${TutorialStep.BuildRadar}`]: 'construir radar',
  [`Hint${TutorialStep.BuildTowerFire}`]: 'construir torre de fogo',
  [`Hint${TutorialStep.BuildTowerFrost}`]: 'construir torre de gelo',

  [`Parameter${ParameterType.Ammo}`]: 'Munição',
  [`Parameter${ParameterType.Resources}`]: 'Recursos',
  [`Parameter${ParameterType.Mobs}`]: 'Mobs',
  [`Parameter${ParameterType.Health}`]: 'Saúde',

  NotEnoughAmmo: 'Munição insuficiente',
  NeedRepair: 'Precisa\nde reparo',
  Upgraded: 'Melhorado',

  Wave: 'Onda',
  PrepareForAttack: 'Prepare-se para o ataque',
  BossWaveStarted: 'Boss wave started',
  WaveStarted: 'Onda %1 iniciada',
  WaveCompleted: 'Onda %1 concluída',
  UntilWaveStart: 'Até o início\nda onda',
  EnemiesLeft: 'Inimigos\nrestantes',

  DefendBase: 'Proteja a base\ndos inimigos',

  Experience: 'Experiência',
  Upgrades: 'Melhorias',
  MaxLevel: 'Nível máximo',

  SkillDuration: 'Duração %1 s',
  SkillRecovery: 'Recuperação %1 s',
  PassiveSkill: 'Passivo',

  NewBuildingsAvailable: 'Novos edifícios\ndisponíveis',

  Empty: 'Vazio',
  Extra: 'Extra',

  Get: 'Obter',
  Buy: 'Comprar',
  Select: 'Selecionar',
  Selected: 'Selecionado',

  Free: 'Gratuito',
  YouHave: 'Você tem',
  Crystals: 'Cristais',
  Slot: 'Slot',
  SelectSlotForSkill: 'Selecione um slot para a habilidade',

  [`Category${InventoryItemType.Droid}`]: 'Droid',
  [`Category${InventoryItemType.Skill}`]: 'Habilidades',

  [`${DroidVariant.Combat}Name`]: 'Combate',
  [`${DroidVariant.Combat}Description`]: 'Ataca mobs inimigos dentro de um certo raio',
  [`${DroidVariant.Medic}Name`]: 'Médico',
  [`${DroidVariant.Medic}Description`]: 'Cura o personagem em intervalos regulares',
  [`${DroidVariant.Mechanic}Name`]: 'Mecânico',
  [`${DroidVariant.Mechanic}Description`]: 'Repara edifícios danificados dentro de um certo raio',

  [`${SkillVariant.DiscountRepair}Name`]: 'Desconto em reparo',
  [`${SkillVariant.DiscountRepair}Description`]: 'Reduz o custo de reparo das construções em 20%',
  [`${SkillVariant.DistantVision}Name`]: 'Visão distante',
  [`${SkillVariant.DistantVision}Description`]: 'Aumenta a distância visível em 20%',
  [`${SkillVariant.Vampire}Name`]: 'Vampirismo',
  [`${SkillVariant.Vampire}Description`]: 'Restaura a saúde em 5% do dano causado',
  [`${SkillVariant.BoostSpeed}Name`]: 'Aumentar velocidade',
  [`${SkillVariant.BoostSpeed}Description`]: 'Aumenta a velocidade em 50%',
  [`${SkillVariant.InspirationDroid}Name`]: 'Droid inspirador',
  [`${SkillVariant.InspirationDroid}Description`]: 'Aumenta as características do droid em 20%',
  [`${SkillVariant.InspirationMobs}Name`]: 'Inspiração de mobs',
  [`${SkillVariant.InspirationMobs}Description`]: 'Aumenta o dano e a velocidade dos mobs em 10%',
  [`${SkillVariant.InspirationTowers}Name`]: 'Inspiração de torres',
  [`${SkillVariant.InspirationTowers}Description`]: 'Aumenta o dano e a velocidade de ataque das torres em 20%',
  [`${SkillVariant.Sacrifice}Name`]: 'Sacrifício',
  [`${SkillVariant.Sacrifice}Description`]: 'Mata os seus mobs e aumenta a sua saúde',
  [`${SkillVariant.DiscountWalls}Name`]: 'Desconto em paredes',
  [`${SkillVariant.DiscountWalls}Description`]: 'Reduz o custo das paredes em 50%',
  [`${SkillVariant.DamageReflection}Name`]: 'Reflexão de dano',
  [`${SkillVariant.DamageReflection}Description`]: 'Reflete 10% do dano recebido',
  [`${SkillVariant.BoostGenerators}Name`]: 'Impulso nos geradores',
  [`${SkillVariant.BoostGenerators}Description`]: 'Aumenta a velocidade de geração de recursos em 10%',
  [`${SkillVariant.CallingMobs}Name`]: 'Invocar mobs',
  [`${SkillVariant.CallingMobs}Description`]: 'Gera mobs perto do personagem',
  [`${SkillVariant.AttackRecovery}Name`]: 'Ataques rápidos',
  [`${SkillVariant.AttackRecovery}Description`]: 'Aumenta a velocidade de recuperação dos ataques em 15%',
  [`${SkillVariant.HighDamage}Name`]: 'Dano elevado',
  [`${SkillVariant.HighDamage}Description`]: 'Aumenta o dano do personagem em 15%',
  [`${SkillVariant.FrostWave}Name`]: 'Onda de Gelo',
  [`${SkillVariant.FrostWave}Description`]: 'Congela os mobs inimigos em um determinado raio',

  [BuildingCategory.Defense]: 'Defesa',
  [BuildingCategory.Spawners]: 'Invocadores',
  [BuildingCategory.Resources]: 'Recursos',
  [BuildingCategory.Towers]: 'Torres',

  [`${BuildingVariant.Base}Name`]: 'Estação base',
  [`${BuildingVariant.Wall}Name`]: 'Parede',
  [`${BuildingVariant.Ammunition}Name`]: 'Munições',
  [`${BuildingVariant.Radar}Name`]: 'Radar',
  [`${BuildingVariant.Trap}Name`]: 'Armadilha',
  [`${BuildingVariant.Generator}Name`]: 'Gerador',
  [`${BuildingVariant.SpawnerSmall}Name`]: 'Invocador',
  [`${BuildingVariant.SpawnerMedium}Name`]: 'Invocador Extra',
  [`${BuildingVariant.SpawnerLarge}Name`]: 'Invocador Ultra',
  [`${BuildingVariant.TowerFire}Name`]: 'Torre de Fogo',
  [`${BuildingVariant.TowerElectro}Name`]: 'Torre de Elétrica',
  [`${BuildingVariant.TowerFrost}Name`]: 'Torre de Gelo',
  [`${BuildingVariant.TowerLaser}Name`]: 'Torre de Laser',

  [UpgradeCategory.Main]: 'Principal',
  [UpgradeCategory.Attack]: 'Ataque',
  [UpgradeCategory.Build]: 'Construção',

  [UpgradeVariant.MainMaxHealth]: 'Vida Máxima',
  [UpgradeVariant.MainSpeed]: 'Velocidade de Movimento',
  [UpgradeVariant.MainDroid]: 'Eficiência do Droid',
  [UpgradeVariant.AttackRecovery]: 'Recuperação de Ataque',
  [UpgradeVariant.AttackRadius]: 'Raio de Ataque',
  [UpgradeVariant.AttackDamage]: 'Dano',
  [UpgradeVariant.BuildSpeed]: 'Velocidade de Construção',
  [UpgradeVariant.BuildRadius]: 'Raio de Construção',

  [SettingsType.FpsLimit]: 'Limite de FPS',
  [SettingsType.Resolution]: 'Qualidade gráfica',
  [SettingsType.VisualEffects]: 'Efeitos visuais',
  [SettingsType.AudioEffects]: 'Efeitos de áudio',
  [SettingsType.Fullscreen]: 'Ecrã completo',
  [SettingsType.Language]: 'Idioma',

  [LanguageType.EN]: 'English',
  [LanguageType.RU]: 'Русский',
  [LanguageType.PT]: 'Português',
  [LanguageType.FR]: 'Français',
};

export default PHRASES;
