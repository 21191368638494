export enum SettingsType {
  FpsLimit = 'FpsLimit',
  Resolution = 'Resolution',
  VisualEffects = 'VisualEffects',
  AudioEffects = 'AudioEffects',
  Fullscreen = 'Fullscreen',
  Language = 'Language',
}

export enum SettingsSwitch {
  On = 'On',
  Off = 'Off'
}

