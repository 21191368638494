import React from 'react';
import { useRecoilState } from 'recoil';

import { Parameters } from './parameters';
import { InventoryPurchaseState } from './state';
import { INVENTORY_ITEM_ICON } from '../../../const';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRequest } from '~/client/core/request/ui/hooks/use-request';
import { SDK } from '~/client/core/sdk';
import { SDKAdType } from '~/client/core/sdk/types';
import { Crystals } from '~/client/core/ui/components/amount/crystals';
import { Button } from '~/client/core/ui/components/button';
import { ButtonBack } from '~/client/core/ui/components/button/back';
import { Icon } from '~/client/core/ui/components/icon';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';
import { InventoryRequest } from '~/shared/core/inventory/types';
import { InventoryUtils } from '~/shared/core/inventory/utils';

import styles from './styles.module.scss';

import IconAds from './icons/ads.svg';

export const Purchase: React.FC = () => {
  const language = useLanguage();

  const getCommercialItem = useRequest(InventoryRequest.GetCommercialItem);
  const buyItem = useRequest(InventoryRequest.BuyItem);

  const [data, setPurchase] = useRecoilState(InventoryPurchaseState);

  const commercial =
    SDK.enabled && data && InventoryUtils.isItemCommercial(data.item);

  const handleGetCommercial = async () => {
    if (!data) {
      return;
    }

    const success = await SDK.showAd(SDKAdType.Rewarded);
    if (success) {
      await getCommercialItem.fetch({
        item: data.item,
      });
    }

    setPurchase(null);
  };

  const handleBuy = async () => {
    if (!data) {
      return;
    }

    await buyItem.fetch({
      item: data.item,
    });

    setPurchase(null);
  };

  const handleBack = () => {
    setPurchase(null);
  };

  return (
    data && (
      <Section
        direction="vertical"
        align="center"
        className={styles.content}
        gap={64}
      >
        <Section direction="horizontal" align="center" gap={32}>
          <div className={styles.meta}>
            <div className={styles.frame}>
              <Icon
                type={INVENTORY_ITEM_ICON[data.item]}
                className={styles.icon}
              />
            </div>
          </div>
          <Section direction="vertical" gap={12}>
            <Text size="2xl" view="primary" bold uppercase>
              {language(`${data.item}Name`)}
            </Text>
            <Text size="l">{language(`${data.item}Description`)}</Text>
            <Parameters item={data.item} />
          </Section>
        </Section>
        <Section direction="horizontal" gap={16}>
          {commercial && (
            <Button
              onClick={handleGetCommercial}
              view="accent"
              icon={IconAds}
              loading={getCommercialItem.loading}
            >
              {language('Get')}
            </Button>
          )}
          <Button
            onClick={handleBuy}
            view={commercial ? undefined : data.allow ? 'accent' : 'negative'}
            loading={buyItem.loading}
            disabled={!data.allow}
          >
            {language('Buy')} <Crystals value={data.cost} size="s" />
          </Button>
          <ButtonBack onClick={handleBack} />
        </Section>
      </Section>
    )
  );
};
