import type { MapSchema, Schema, SetSchema } from '@colyseus/schema';

export interface InventorySchema extends Schema {
  readonly slots: MapSchema<string>;
  readonly items: SetSchema<string>;
}

export enum InventoryRequest {
  ClearSlot = 'Inventory:ClearSlot',
  UpdateSlot = 'Inventory:UpdateSlot',
  BuySlot = 'Inventory:BuySlot',
  BuyItem = 'Inventory:BuyItem',
  GetCommercialItem = 'Inventory:GetCommercialItem',
}
