import { Indicator } from './indicator';
import { BattleSceneLayer } from '../../scene/types';

import type { Battle } from '../..';
import type { CrystalSchema } from '~/shared/battle/terrain/crystal/types';

import { MaterialType } from '~/client/core/assets/materials/types';
import { ModelType } from '~/client/core/assets/types';
import { Model } from '~/client/core/render-item/model';

import './resources';

export class Crystal {
  public readonly battle: Battle;

  public readonly schema: CrystalSchema;

  public readonly renderItem: Model;

  private readonly indicator: Indicator;

  constructor(battle: Battle, schema: CrystalSchema) {
    this.battle = battle;
    this.schema = schema;

    this.renderItem = new Model(battle.scene, {
      model: ModelType.Crystal,
      material: MaterialType.Crystal,
      position: schema.position,
      scale: schema.big ? 1.5 : 0,
    });
    this.renderItem.eachMeshes((mesh) => {
      mesh.layers.set(BattleSceneLayer.Misc);
    });

    this.renderItem.animator.play('spin', { timeScale: 0.75 });

    this.indicator = new Indicator(this);

    this.listenSchemaVisible();

    this.schema.onRemove(() => {
      this.destroy();
    });

    this.battle.terrain.crystals.add(this);
  }

  public destroy(): void {
    this.indicator.destroy();
    this.renderItem.destroy();

    this.battle.terrain.crystals.delete(this);
  }

  private listenSchemaVisible() {
    const selfId = this.battle.getSelfPlayerSchema().id;

    this.schema.visibleFor.onChange(() => {
      const visible = Boolean(this.schema.visibleFor.get(selfId));
      this.renderItem.setVisible(visible);
    });
  }
}
