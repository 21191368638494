import React from 'react';
import { useRecoilState } from 'recoil';

import { INVENTORY_ITEM_ICON } from '../../../../const';
import { useInventoryItem } from '../../../../hooks/use-inventory-item';
import { InventorySlotSelectState } from '../state';

import { useRequest } from '~/client/core/request/ui/hooks/use-request';
import { Icon } from '~/client/core/ui/components/icon';
import { Interactive } from '~/client/core/ui/components/interactive';
import { InventoryItemType } from '~/shared/core/inventory/item/types';
import { InventoryRequest } from '~/shared/core/inventory/types';

import styles from './styles.module.scss';

type Props = {
  index: number;
};

export const Slot: React.FC<Props> = ({ index }) => {
  const updateSlot = useRequest(InventoryRequest.UpdateSlot);

  const current = useInventoryItem(InventoryItemType.Skill, index);
  const [slotSelect, setSlotSelect] = useRecoilState(InventorySlotSelectState);

  const handleClick = async () => {
    await updateSlot.fetch({
      item: slotSelect,
      slot: index,
    });

    setSlotSelect(null);
  };

  return (
    <Interactive
      onClick={handleClick}
      disabled={updateSlot.loading}
      className={styles.frame}
    >
      {current ? (
        <Icon type={INVENTORY_ITEM_ICON[current]} className={styles.icon} />
      ) : (
        <div className={styles.dot} />
      )}
    </Interactive>
  );
};
