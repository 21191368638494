import cn from 'classnames';
import React, { useEffect, useRef } from 'react';

import { Icon } from '../icon';

import { Utils } from '~/shared/core/utils';

import styles from './styles.module.scss';

type Props = {
  icon: SVGIcon;
  color: string;
  value: number;
  size?: 's' | 'm' | 'l';
  animated?: boolean;
  prefix?: string;
};

export const Amount: React.FC<Props> = ({
  icon,
  color,
  value,
  animated,
  prefix,
  size = 's',
}) => {
  const refValue = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const text = refValue.current;
    if (!animated || !text) {
      return;
    }

    const growth = Math.ceil(value / 200);
    let currentValue = 0;
    text.innerText = '0';

    const timer = setInterval(() => {
      currentValue = Math.min(currentValue + growth, value);
      text.innerText = currentValue.toString();
      if (currentValue === value) {
        clearInterval(timer);
      }
    }, 10);

    return () => {
      clearInterval(timer);
    };
  }, [value]);

  return (
    <div className={cn(styles.wrapper, styles[size])}>
      <Icon type={icon} className={styles.icon} color={color} />
      <div ref={refValue} className={styles.amount}>
        {prefix}
        {Utils.decorateNumber(value)}
      </div>
    </div>
  );
};
