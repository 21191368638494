import React from 'react';

import { Icon } from '~/client/core/ui/components/icon';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';

import styles from './styles.module.scss';

import IconResources from './icons/resources.svg';

type Props = {
  value: number;
  className?: string;
};

export const Resources: React.FC<Props> = ({ value, className }) => (
  <Section direction='horizontal' align='center' gap={4} className={className}>
    <Icon type={IconResources} size={14} />
    <Text size='xs' bold className={styles.amount}>{value}</Text>
  </Section>
);

