import cn from 'classnames';
import React from 'react';

import type { LeaderboardRecord } from '~/shared/relay/leaderboard/types';

import { Score } from '~/client/core/ui/components/amount/score';
import { Waves } from '~/client/core/ui/components/amount/waves';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';

import styles from './styles.module.scss';

type Props = LeaderboardRecord;

export const Leader: React.FC<Props> = ({ place, name, score, waves }) => (
  <Section
    direction="horizontal"
    align="center"
    gap={48}
    className={cn(styles.wrapper, styles[`place${place}`])}
  >
    <div className={styles.place}>
      <Text size="2xl" bold>
        {place}
      </Text>
    </div>
    <div className={styles.gradient} />
    <Section direction="vertical" gap={16} className={styles.info}>
      <Text size="xl" bold>
        {name}
      </Text>
      <Section direction="vertical" gap={8}>
        <Score value={score} size="s" />
        <Waves value={waves} size="s" />
      </Section>
    </Section>
  </Section>
);
