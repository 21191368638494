import React, { useRef } from 'react';

import { ErrorPlate } from '../../../../../ui/components/error-plate';
import { GoogleButton } from '../google-button';
import { InputPassword } from '../inputs/password';
import { InputUsername } from '../inputs/username';

import type { FormSignUpFields } from './types';
import type { FormData } from '~/client/core/ui/components/form/types';

import { Client } from '~/client/core/client';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRequest } from '~/client/core/request/ui/hooks/use-request';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Button } from '~/client/core/ui/components/button';
import { Checkbox } from '~/client/core/ui/components/checkbox';
import { useError } from '~/client/core/ui/components/error-plate/hooks/use-error';
import { Form } from '~/client/core/ui/components/form';
import { Section } from '~/client/core/ui/components/section';
import { UserAuthRequest } from '~/shared/core/user/auth/types';

export const AuthSingUp: React.FC = () => {
  const room = useRoom();
  const error = useError();
  const language = useLanguage();

  const register = useRequest<string>(UserAuthRequest.Register);

  const refForm = useRef<FormData>(null);

  const handleClickSingUp = () => {
    refForm.current?.submit();
  };

  const handleSubmit = (values: FormSignUpFields) => {
    register.fetch({
      username: values.username,
      password: values.password,
    }).then((token) => {
      Client.setAuthToken(token);
      room.refreshUI();
    }).catch(({ message }) => {
      error.show(language(message));
    });
  };

  return (
    <Form ref={refForm} onSubmit={handleSubmit}>
      <Section direction="vertical" gap={48}>
        <Section direction="vertical" gap={24}>
          <Section direction="vertical" gap={8}>
            <ErrorPlate error={error} />
            <InputUsername />
            <InputPassword />
          </Section>
          <Checkbox name='acceptPolicy'>
            {language('AcceptTerms')}
            <br />
            <a
              href="https://izowave.io/policy"
              target="_blank"
            >
              {language('PrivacyPolicy')}
            </a>
          </Checkbox>
        </Section>
        <Section direction="horizontal" gap={16}>
          <Button
            view='accent'
            size='small'
            loading={register.loading}
            onClick={handleClickSingUp}
          >
            {language('ToSignUp')}
          </Button>
          <GoogleButton />
        </Section>
      </Section>
    </Form>
  );
};
