import React, { useEffect } from 'react';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRequest } from '~/client/core/request/ui/hooks/use-request';
import { RelayRequest } from '~/shared/relay/types';

import styles from './styles.module.scss';

import IconSearching from './icons/searching.svg';

export const SearchingOpponent: React.FC = () => {
  const language = useLanguage();

  const stopSearchingOpponent = useRequest(RelayRequest.StopSearchingOpponent);

  useEffect(() => {
    return () => {
      stopSearchingOpponent.fetch();
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <IconSearching />
      <div className={styles.label}>
        {language('SearchingOpponent')}
      </div>
    </div>
  );
};
