import React from 'react';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Icon } from '~/client/core/ui/components/icon';
import { Interactive } from '~/client/core/ui/components/interactive';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';

import styles from './styles.module.scss';

import IconAuth from './icons/auth.svg';

type Props = {
  onClick: VoidFunction;
};

export const AuthOffer: React.FC<Props> = ({ onClick }) => {
  const language = useLanguage();

  return (
    <div className={styles.position}>
      <div className={styles.wrapper}>
        <Interactive onClick={onClick} className={styles.body} />
        <div className={styles.container}>
          <div className={styles.frame}>
            <Icon type={IconAuth} className={styles.icon} />
          </div>
          <Section direction='vertical' gap={6}>
            <Text size='s' uppercase>
              {language('AuthTo')}
            </Text>
            <Text size='l' bold uppercase>
              {language('SaveReward')}
            </Text>
          </Section>
        </div>
      </div>
    </div>
  );
};
