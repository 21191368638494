import React, { useState } from 'react';

import type { Battle } from '~/client/battle';

import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { useEvent } from '~/client/core/ui/hooks/use-event';

import styles from './styles.module.scss';

export const Entities: React.FC = () => {
  const battle = useRoom<Battle>();

  const [components, setComponents] = useState(battle.entitiesUI);

  useEvent(battle.onEntityChangeUI, () => {
    setComponents(new Map(battle.entitiesUI));
  }, []);

  return (
    <div className={styles.translator}>
      {Array.from(components.entries()).map(([entity, Component]) => !entity.disposed && (
        <Component key={entity.renderItem.id} target={entity} />
      ))}
    </div>
  );
};
