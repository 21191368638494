import { Events } from 'make-event';
import { MathUtils, type Vector2Like } from 'three';

import { DEVICE_SCREEN_SCALE, DEVICE_TARGET_SCREEN_SIZE } from './const';
import { SystemMessage } from '../system-message';
import { SystemMessageType } from '../system-message/types';

import { DeviceType } from '~/shared/core/device/types';

export class Device {
  public static readonly onScreenResize = Events.make();
  public static readonly onScreenHide = Events.make();
  public static readonly onScreenRelease = Events.make();
  public static readonly onExit = Events.make();

  public static type: DeviceType = (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      ? DeviceType.Mobile
      : DeviceType.Desktop
  );

  public static isMacOS: boolean = (
    navigator.platform.toUpperCase().indexOf('MAC') >= 0
  );

  public static get isMobile() {
    return this.type === DeviceType.Mobile;
  }

  public static get isDesktop() {
    return this.type === DeviceType.Desktop;
  }

  public static get isLandscape() {
    return window.innerWidth > window.innerHeight;
  }

  public static getWrapper() {
    const wrapper = document.getElementById('game');
    if (!wrapper) {
      throw Error('Device game wrapper isn`t found');
    }

    return wrapper;
  }

  public static getScreenSize(): Vector2Like {
    return this.isLandscape ? {
      x: window.innerWidth,
      y: window.innerHeight,
    } : {
      x: window.innerHeight,
      y: window.innerWidth,
    };
  }

  public static getPositionOnScreen(position: Vector2Like): Vector2Like {
    return this.isLandscape ? {
      x: position.x,
      y: position.y,
    } : {
      x: position.y,
      y: window.innerWidth - position.x,
    };
  }

  public static normalizePosition(position: Vector2Like) {
    const screenSize = this.getScreenSize();
    return {
      x: (position.x / screenSize.x) * 2 - 1,
      y: -(position.y / screenSize.y) * 2 + 1,
    };
  }

  public static getScale(): number {
    const screenSize = Device.getScreenSize();
    const scaleX = MathUtils.clamp(
      screenSize.x / DEVICE_TARGET_SCREEN_SIZE.width,
      DEVICE_SCREEN_SCALE.min,
      DEVICE_SCREEN_SCALE.max,
    );
    const scaleY = MathUtils.clamp(
      screenSize.y / DEVICE_TARGET_SCREEN_SIZE.height,
      DEVICE_SCREEN_SCALE.min,
      DEVICE_SCREEN_SCALE.max,
    );

    return Math.min(scaleX, scaleY);
  }

  public static addPlatformLabel() {
    const label = this.type.toLowerCase();
    this.getWrapper().setAttribute('data-device', label);
  }

  public static addListeners() {
    this.calculateViewportSize();
    window.addEventListener('resize', () => {
      this.calculateViewportSize();
      this.onScreenResize.invoke();
    });

    window.addEventListener('blur', () => {
      this.onScreenHide.invoke();
    });

    window.addEventListener('beforeunload', () => {
      this.onScreenHide.invoke();
      this.onExit.invoke();
    });

    window.addEventListener('focus', () => {
      this.onScreenRelease.invoke();
    });
  }

  private static isWebGLSupport() {
    try {
      const canvas = document.createElement('canvas');
      return Boolean(
        WebGLRenderingContext &&
        (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')),
      );
    } catch (e) {
      return false;
    }
  }

  public static checkSupports() {
    const webgl = this.isWebGLSupport();
    if (!webgl) {
      SystemMessage.render('invalid-browser', {
        type: SystemMessageType.Error,
        sign: '⚠️',
        title: 'Unsupported Browser',
        message: 'Your browser do not support WebGL feature',
      });
    }

    return webgl;
  }

  private static calculateViewportSize() {
    const rootStyle = document.documentElement.style;
    rootStyle.setProperty('--viewport-width', `${window.innerWidth}px`);
    rootStyle.setProperty('--viewport-height', `${window.innerHeight}px`);
  }
}
