import { useContext } from 'react';

import { FormContext } from '../context';

export function useForm() {
  const form = useContext(FormContext);
  if (!form) {
    throw Error('Undefined form context');
  }

  return form;
}
