import React from 'react';

import { Sheet } from '../../sheet';

import { SettingsPanel } from '~/client/core/ui/components/settings-panel';

export const PageSettings: React.FC = () => (
  <Sheet>
    <SettingsPanel />
  </Sheet>
);

