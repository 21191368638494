import cn from 'classnames';
import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { INVENTORY_ITEM_ICON } from '../../../const';
import { useInventoryItemSlot } from '../../../hooks/use-inventory-item-slot';
import { InventoryPurchaseState } from '../purchase/state';
import { InventorySlotSelectState } from '../slot-select/state';

import type { SkillVariant } from '~/shared/battle/entity/unit/player/skill/types';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRequest } from '~/client/core/request/ui/hooks/use-request';
import { SDK } from '~/client/core/sdk';
import { Crystals } from '~/client/core/ui/components/amount/crystals';
import { Icon } from '~/client/core/ui/components/icon';
import { Interactive } from '~/client/core/ui/components/interactive';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';
import { useUserData } from '~/client/relay/ui/hooks/use-user-data';
import { InventoryItemType } from '~/shared/core/inventory/item/types';
import { InventoryRequest } from '~/shared/core/inventory/types';
import { InventoryUtils } from '~/shared/core/inventory/utils';

import styles from './styles.module.scss';

import IconAds from './icons/ads.svg';

type Props = {
  item: SkillVariant;
  have?: boolean
};

export const InventoryItem: React.FC<Props> = ({ item, have }) => {
  const language = useLanguage();

  const clearSlot = useRequest(InventoryRequest.ClearSlot);

  const crystals = useUserData('crystals');

  const usedSlot = useInventoryItemSlot(item);

  const [, setPurchase] = useRecoilState(InventoryPurchaseState);
  const [, setSlotSelect] = useRecoilState(InventorySlotSelectState);

  const commercial = SDK.enabled && InventoryUtils.isItemCommercial(item);
  const active = usedSlot !== null;
  const cost = InventoryUtils.getItemCost(item);
  const allow = crystals >= cost;

  const handleClick = useCallback(() => {
    if (have) {
      if (active) {
        clearSlot.fetch({
          type: InventoryItemType.Skill,
          slot: usedSlot,
        });
      } else {
        setSlotSelect(item);
      }
    } else {
      setPurchase({
        item,
        cost,
        allow,
      });
    }
  }, [have, active, allow]);

  return (
    <Interactive className={cn(styles.wrapper, {
      [styles.have]: have,
      [styles.active]: active,
    })} onClick={handleClick}>
      <div className={styles.meta}>
        <div className={styles.frame}>
          <Icon type={INVENTORY_ITEM_ICON[item]} className={styles.icon} />
        </div>
      </div>
      <Section direction='vertical' align='left' gap={8}>
        <Text view='primary' size='l' bold uppercase>
          {language(`${item}Name`)}
        </Text>
        <Text size='xs'>
          {language(`${item}Description`)}
        </Text>
        {!have && (
          commercial ? (
            <Section direction='horizontal' align='center' gap={8}>
              <IconAds className={styles.commercialIcon} />
              <Text size='s' bold uppercase>
                {language('Free')}
              </Text>
            </Section>
          ) : (
            <Crystals value={cost} />
          )
        )}
        {active && (
          <div className={styles.slot}>
            <Text size='xs' view='dark' bold uppercase nowrap>
              {language('Slot')} {usedSlot + 1}
            </Text>
          </div>
        )}
      </Section>
    </Interactive>
  );
};
