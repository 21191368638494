import { useSchemaListener } from 'colyseus-schema-hooks';
import { useState } from 'react';

import { useUserData } from '../../../../hooks/use-user-data';

import { useBatch } from '~/client/core/ui/hooks/use-batch';
import { InventoryUtils } from '~/shared/core/inventory/utils';

export function useInventoryItemSlot(item: string) {
  const inventory = useUserData('inventory');

  const getSlot = () => (
    InventoryUtils.getItemSlot(inventory, item)
  );

  const [slot, setSlot] = useState(getSlot);

  const update = useBatch(() => {
    setSlot(getSlot());
  });

  useSchemaListener(inventory.slots, {
    onAdd: update,
    onChange: update,
    onRemove: update,
  });

  return slot;
}
