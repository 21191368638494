import React, { useMemo } from 'react';

type Props = {
  type?: Nullable<SVGIcon>;
  className?: string;
  size?: number;
  color?: string;
};

export const Icon: React.FC<Props> = ({ type, className, color, size }) => {
  const IconComponent = type;

  const style = useMemo(() => (
    {
      ...(color ? { fill: color } : {}),
      ...(size ? { width: size, height: size } : {}),
    }
  ), [color, size]);

  return IconComponent && (
    <IconComponent className={className} style={style} />
  );
};
