import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';

import { Parameter } from './parameter';
import { ParameterType } from './parameter/types';

import type { Battle } from '~/client/battle';

import { useSelfPlayerSchema } from '~/client/battle/ui/hooks/use-self-player-schema';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { BattleMode } from '~/shared/battle/types';

import styles from './styles.module.scss';

export const PlayerInfoPanel: React.FC = () => {
  const battle = useRoom<Battle>();
  const player = useSelfPlayerSchema();

  const health = useSchemaValue(player.live, 'health');
  const resources = useSchemaValue(player, 'resources');
  const ammo = useSchemaValue(player, 'ammo');
  const mobs = useSchemaValue(player, 'mobsCount');

  const onlineMode = battle.state.mode === BattleMode.Online;

  return (
    <div className={styles.parameters}>
      <Parameter type={ParameterType.Health} value={health} />
      <Parameter type={ParameterType.Resources} value={resources} />
      <Parameter type={ParameterType.Ammo} value={ammo} />
      {onlineMode && (
        <Parameter type={ParameterType.Mobs} value={mobs} />
      )}
    </div>
  );
};
