import React, { useCallback, useState } from 'react';

import { Kind } from './kind';
import { SearchingOpponent } from './searching-opponent';
import { SelectDifficult } from './select-difficult';
import { PlayKind } from './types';
import { WithFriend } from './with-friend';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRequest } from '~/client/core/request/ui/hooks/use-request';
import { ButtonBack } from '~/client/core/ui/components/button/back';
import { Section } from '~/client/core/ui/components/section';
import { RelayRequest } from '~/shared/relay/types';

import IconOnlinePrivate from './icons/online-private.svg';
import IconOnline from './icons/online.svg';
import IconSingle from './icons/single.svg';

export const PagePlay: React.FC = () => {
  const language = useLanguage();

  const startSearchingOpponent = useRequest(RelayRequest.StartSearchingOpponent);

  const [kind, setKind] = useState<Nullable<PlayKind>>(null);

  const handleClickBack = useCallback(() => {
    setKind(null);
  }, []);

  const handleClickPlaySingle = () => {
    setKind(PlayKind.SelectDifficult);
  };

  const handleClickPlayOnline = async () => {
    await startSearchingOpponent.fetch();
    setKind(PlayKind.Online);
  };

  const handleClickPlayOnlinePrivate = () => {
    setKind(PlayKind.OnlinePrivate);
  };

  return (
    <Section direction="vertical" align="center" gap={40}>
      {(() => {
        switch (kind) {
          case PlayKind.SelectDifficult: return <SelectDifficult />;
          case PlayKind.Online: return <SearchingOpponent />;
          case PlayKind.OnlinePrivate: return <WithFriend />;
          default: {
            return (
              <Section direction="vertical" gap={10}>
                <Kind
                  label={language('PlaySingle')}
                  description={language('PlaySingleDescription')}
                  icon={IconSingle}
                  onClick={handleClickPlaySingle}
                  accent
                />
                <Kind
                  label={language('PlayOnline')}
                  description={language('PlayOnlineDescription')}
                  icon={IconOnline}
                  onClick={handleClickPlayOnline}
                  authorizedOnly
                />
                <Kind
                  label={language('PlayOnlinePrivate')}
                  description={language('PlayOnlinePrivateDescription')}
                  icon={IconOnlinePrivate}
                  onClick={handleClickPlayOnlinePrivate}
                  authorizedOnly
                />
              </Section>
            );
          }
        }
      })()}
      {kind && (
        <ButtonBack onClick={handleClickBack} />
      )}
    </Section>
  );
};
