import { useState } from 'react';

import { Logger } from '~/client/core/logger';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';

export function useRequest<T = any, P = any>(type: string) {
  const room = useRoom();

  const [loading, setLoading] = useState(false);

  const fetch = async (payload?: P) => {
    setLoading(true);

    if (loading) {
      Logger.warn(`Request ${type} is already pending`);
    }

    return room.sendRequest<T>(type, payload)
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    fetch,
    loading,
  };
}
