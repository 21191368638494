import React from 'react';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';
import { SKILLS_SHARED_CONFIG } from '~/shared/battle/entity/unit/player/skill/const';
import { SkillType, type SkillVariant } from '~/shared/battle/entity/unit/player/skill/types';

import styles from './styles.module.scss';

type Props = {
  item: SkillVariant;
};

export const Parameters: React.FC<Props> = ({ item }) => {
  const language = useLanguage();

  const { type, duration, recovery } = SKILLS_SHARED_CONFIG[item];

  return (
    <Section direction='vertical' align='left' gap={8}>
      {type === SkillType.Passive ? (
        <Text view='dark' size='s' uppercase className={styles.type}>
          {language('PassiveSkill')}
        </Text>
      )
        : (
          <>
            {duration && (
              <Text view='secondary' size='s' >
                {language('SkillDuration', [duration / 1000])}
              </Text>
            )}
            {recovery && (
              <Text view='secondary' size='s' >
                {language('SkillRecovery', [recovery / 1000])}
              </Text>
            )}
          </>
        )}
    </Section>
  );
};
