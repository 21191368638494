import { PageType } from '../../../relay/ui/components/pages/types';
import { SettingsType } from '../../settings/types';
import { LanguageType } from '../types';

import { ParameterType } from '~/client/battle/entity/unit/player/ui/components/info-panel/parameter/types';
import { KeyAction } from '~/client/battle/tutorial/ui/components/hints/content/key/types';
import { SwipeDirection } from '~/client/battle/tutorial/ui/components/hints/content/swipe/types';
import { BuildingCategory, BuildingVariant } from '~/shared/battle/entity/building/types';
import { DroidVariant } from '~/shared/battle/entity/unit/npc/droid/types';
import { SkillVariant } from '~/shared/battle/entity/unit/player/skill/types';
import { UpgradeCategory, UpgradeVariant } from '~/shared/battle/entity/unit/player/upgrades/types';
import { TutorialStep } from '~/shared/battle/tutorial/types';
import { BattleDifficult } from '~/shared/battle/types';
import { InventoryItemType } from '~/shared/core/inventory/item/types';
import { UserError } from '~/shared/core/user/types';

const PHRASES = {
  [PageType.Play]: 'Играть',
  [PageType.Inventory]: 'Инвентарь',
  [PageType.Leaderboard]: 'Лидеры',
  [PageType.Profile]: 'Профиль',

  LeaveAccount: 'Выйти из аккаунта',
  ToLogIn: 'Войти',
  ToSignUp: 'Зарегистрироваться',
  LogIn: 'Вход',
  SignUp: 'Регистрация',
  Username: 'Логин',
  Password: 'Пароль',
  AcceptTerms: 'Я принимаю условия',
  PrivacyPolicy: 'политики конфиденциальности',

  [UserError.AlreadyAuthorized]: 'Вы уже авторизованы',
  [UserError.ExistUsername]: 'Указанный логин занят',
  [UserError.InvalidToken]: 'Некорректный токен авторизации',
  [UserError.InvalidCredentials]: 'Неправильный логин или пароль',
  [UserError.InvalidUsername]: 'Указан недопустимый логин',
  [UserError.InvalidPassword]: 'Указан недопустимый пароль',

  MainMenu: 'Главное меню',
  Continue: 'Продолжить',
  Restart: 'Рестарт',
  Settings: 'Настройки',
  RestartConfirm: 'Вы уверены что хотите начать сначала?',
  LeaveConfirm: 'Вы уверены что хотите покинуть этот бой?',
  Back: 'Назад',

  Place: 'Место',
  MaxWave: 'Макс волна',
  TotalWaves: 'Всего волн',

  AuthTo: 'Авторизуйтесь чтобы',
  SaveReward: 'Сохранить награду',
  LoadSave: 'Загрузить сохранение',
  PlayAgain: 'Играть сначала',
  WaitingOpponent: 'Ожидание ответа соперника',
  OpponentDisconnected: 'Соперник вышел из боя',

  YouWin: 'Победа',
  YouLose: 'Поражение',
  YouDied: 'Вы погибли',
  BaseDestroyed: 'База разрушена',

  [`Difficult${BattleDifficult.Tutorial}`]: 'Обучение',
  [`Difficult${BattleDifficult.Tutorial}Description`]: 'Для первой игры',
  [`Difficult${BattleDifficult.Normal}`]: 'Нормально',
  [`Difficult${BattleDifficult.Normal}Description`]: 'Для новичков',
  [`Difficult${BattleDifficult.Hard}`]: 'Сложно',
  [`Difficult${BattleDifficult.Hard}Description`]: 'Для опытных игроков',

  PlayerOnline: 'Игрок онлайн',
  PlayersOnline: 'Игроков онлайн',

  YouRanked: 'Вы занимаете',
  PlaceOf: 'место из',

  Earn: 'Наберите',
  UnlockOnlineModes: 'чтобы разблокировать онлайн режимы',

  SearchingOpponent: 'Поиск\nпротивника',
  PlaySingle: 'Одиночная игра',
  PlaySingleDescription: 'Играть в режиме бесконечных волн',
  PlayOnline: 'Онлайн игра',
  PlayOnlineDescription: 'Играть со случайным противником',
  PlayOnlinePrivate: 'Приватная игра',
  PlayOnlinePrivateDescription: 'Играть со своим другом',
  YourId: 'Ваш ID',
  FriendId: 'ID Друга',

  OrHold: 'Или зажмите',

  Yes: 'Да',
  No: 'Нет',

  On: 'Вкл',
  Off: 'Выкл',

  Low: 'Низкое',
  Medium: 'Среднее',
  High: 'Высокое',

  To: 'чтобы',

  [KeyAction.Press]: 'Нажмите',
  [KeyAction.Hold]: 'Зажмите',
  [`Swipe${SwipeDirection.Up}`]: 'Свайп вверх',
  [`Swipe${SwipeDirection.Left}`]: 'Свайп влево',
  [`Hint${TutorialStep.Attack}`]: 'атаковать врагов',
  [`Hint${TutorialStep.UpgradeGenerator}`]: 'Нажмите на генератор\nчтобы улучшить',
  [`Hint${TutorialStep.UpgradeTowerFire}`]: 'Нажмите на башню\nчтобы улучшить',
  [`Hint${TutorialStep.UpgradePlayer}`]: 'улучшить персонажа',
  [`Hint${TutorialStep.BuildGenerator}`]: 'построить генератор',
  [`Hint${TutorialStep.BuildSecondGenerator}`]: 'построить еще один генератор',
  [`Hint${TutorialStep.BuildAmmunition}`]: 'построить склад патронов',
  [`Hint${TutorialStep.BuildSecondAmmunition}`]: 'построить еще один склад патронов',
  [`Hint${TutorialStep.BuildRadar}`]: 'построить радар',
  [`Hint${TutorialStep.BuildTowerFire}`]: 'построить огненную башню',
  [`Hint${TutorialStep.BuildTowerFrost}`]: 'построить ледяную башню',

  [`Parameter${ParameterType.Ammo}`]: 'Патроны',
  [`Parameter${ParameterType.Resources}`]: 'Ресурсы',
  [`Parameter${ParameterType.Mobs}`]: 'Мобы',
  [`Parameter${ParameterType.Health}`]: 'Здоровье',

  NotEnoughAmmo: 'Недостаточно патронов',
  NeedRepair: 'Требуется ремонт',
  Upgraded: 'Улучшено',

  Wave: 'Волна',
  PrepareForAttack: 'Приготовьтесь к атаке',
  BossWaveStarted: 'Boss wave started',
  WaveStarted: 'Волна %1 началась',
  WaveCompleted: 'Волна %1 завершилась',
  UntilWaveStart: 'До начала\nволны',
  EnemiesLeft: 'Осталось\nврагов',

  DefendBase: 'Защитите базу\nот врагов',

  Experience: 'Опыт',
  Upgrades: 'Улучшения',
  MaxLevel: 'Макс. уровень',

  SkillDuration: 'Длительность %1 сек',
  SkillRecovery: 'Восстановление %1 сек',
  PassiveSkill: 'Пассивный',

  NewBuildingsAvailable: 'Доступны новые\nпостройки',

  Empty: 'Пустой',
  Extra: 'Дополнительный',

  Get: 'Получить',
  Buy: 'Купить',
  Select: 'Выбрать',
  Selected: 'Выбрано',

  Free: 'Бесплатно',
  YouHave: 'У Вас',
  Crystals: 'Кристаллов',
  Slot: 'Слот',
  SelectSlotForSkill: 'Выберите слот для навыка',

  [`Category${InventoryItemType.Droid}`]: 'Дроид',
  [`Category${InventoryItemType.Skill}`]: 'Навыки',

  [`${DroidVariant.Combat}Name`]: 'Боевой',
  [`${DroidVariant.Combat}Description`]: 'Атакует вражеских мобов в определенном радиусе',
  [`${DroidVariant.Medic}Name`]: 'Медик',
  [`${DroidVariant.Medic}Description`]: 'Лечит персонажа с определенным интервалом',
  [`${DroidVariant.Mechanic}Name`]: 'Механик',
  [`${DroidVariant.Mechanic}Description`]: 'Ремонтирует поврежденные здания в определенном радиусе',

  [`${SkillVariant.DiscountRepair}Name`]: 'Скидка на ремонт',
  [`${SkillVariant.DiscountRepair}Description`]: 'Уменьшает цену ремонта построек на 20%',
  [`${SkillVariant.DistantVision}Name`]: 'Дальнее зрение',
  [`${SkillVariant.DistantVision}Description`]: 'Увеличивает видимую дистанцию на 20%',
  [`${SkillVariant.Vampire}Name`]: 'Вампиризм',
  [`${SkillVariant.Vampire}Description`]: 'Восстанавливает здоровье на 5% от нанесенного урона',
  [`${SkillVariant.BoostSpeed}Name`]: 'Буст скорости',
  [`${SkillVariant.BoostSpeed}Description`]: 'Увеличивает скорость на 50%',
  [`${SkillVariant.InspirationDroid}Name`]: 'Воодушевление дроида',
  [`${SkillVariant.InspirationDroid}Description`]: 'Увеличивает характеристики дроида на 20%',
  [`${SkillVariant.InspirationMobs}Name`]: 'Воодушевление мобов',
  [`${SkillVariant.InspirationMobs}Description`]: 'Увеличивает урон и скорость мобов на 10%',
  [`${SkillVariant.InspirationTowers}Name`]: 'Воодушевление башен',
  [`${SkillVariant.InspirationTowers}Description`]: 'Увеличивает урон и скорость атаки башен на 20%',
  [`${SkillVariant.Sacrifice}Name`]: 'Жертвоприношение',
  [`${SkillVariant.Sacrifice}Description`]: 'Убивает ваших мобов и восстанавливает ваше здоровье',
  [`${SkillVariant.DiscountWalls}Name`]: 'Дешевые стены',
  [`${SkillVariant.DiscountWalls}Description`]: 'Снижает цену стен на 50%',
  [`${SkillVariant.DamageReflection}Name`]: 'Отражение урона',
  [`${SkillVariant.DamageReflection}Description`]: 'Отражает 10% от полученного урона',
  [`${SkillVariant.BoostGenerators}Name`]: 'Буст генераторов',
  [`${SkillVariant.BoostGenerators}Description`]: 'Увеличивает скорость генерации ресурсов на 10%',
  [`${SkillVariant.CallingMobs}Name`]: 'Призыв мобов',
  [`${SkillVariant.CallingMobs}Description`]: 'Спавнит мобов рядом с персонажем',
  [`${SkillVariant.AttackRecovery}Name`]: 'Быстрые атаки',
  [`${SkillVariant.AttackRecovery}Description`]: 'Увеличивает скорость восстановления атаки на 15%',
  [`${SkillVariant.HighDamage}Name`]: 'Повышенный урон',
  [`${SkillVariant.HighDamage}Description`]: 'Увеличивает урон персонажа на 15%',
  [`${SkillVariant.FrostWave}Name`]: 'Ледяная волна',
  [`${SkillVariant.FrostWave}Description`]: 'Замораживает вражеских мобов в определенном радиусе',

  [BuildingCategory.Defense]: 'Защита',
  [BuildingCategory.Spawners]: 'Спавнеры',
  [BuildingCategory.Resources]: 'Ресурсы',
  [BuildingCategory.Towers]: 'Башни',

  [`${BuildingVariant.Base}Name`]: 'Станция базы',
  [`${BuildingVariant.Wall}Name`]: 'Стена',
  [`${BuildingVariant.Ammunition}Name`]: 'Боеприпасы',
  [`${BuildingVariant.Radar}Name`]: 'Радар',
  [`${BuildingVariant.Trap}Name`]: 'Ловушка',
  [`${BuildingVariant.Generator}Name`]: 'Генератор',
  [`${BuildingVariant.SpawnerSmall}Name`]: 'Спавнер',
  [`${BuildingVariant.SpawnerMedium}Name`]: 'Экстра-спавнер',
  [`${BuildingVariant.SpawnerLarge}Name`]: 'Ультра-спавнер',
  [`${BuildingVariant.TowerFire}Name`]: 'Огненная Башня',
  [`${BuildingVariant.TowerElectro}Name`]: 'Электро Башня',
  [`${BuildingVariant.TowerFrost}Name`]: 'Ледяная Башня',
  [`${BuildingVariant.TowerLaser}Name`]: 'Лазерная Башня',

  [UpgradeCategory.Main]: 'Основное',
  [UpgradeCategory.Attack]: 'Атака',
  [UpgradeCategory.Build]: 'Строительство',

  [UpgradeVariant.MainMaxHealth]: 'Количество жизней',
  [UpgradeVariant.MainSpeed]: 'Скорость движения',
  [UpgradeVariant.MainDroid]: 'Эффективность дроида',
  [UpgradeVariant.AttackRecovery]: 'Восстановление атаки',
  [UpgradeVariant.AttackRadius]: 'Радиус атаки',
  [UpgradeVariant.AttackDamage]: 'Урон',
  [UpgradeVariant.BuildSpeed]: 'Скорость строительства',
  [UpgradeVariant.BuildRadius]: 'Радиус строительства',

  [SettingsType.FpsLimit]: 'Лимит FPS',
  [SettingsType.Resolution]: 'Качество изображения',
  [SettingsType.VisualEffects]: 'Визуальные эффекты',
  [SettingsType.AudioEffects]: 'Аудио эффекты',
  [SettingsType.Fullscreen]: 'Полноэкранный режим',
  [SettingsType.Language]: 'Язык текста',

  [LanguageType.EN]: 'English',
  [LanguageType.RU]: 'Русский',
  [LanguageType.PT]: 'Português',
  [LanguageType.FR]: 'Français',
};

export default PHRASES;
