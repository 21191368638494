import React from 'react';

import { PARAMETER_ICON } from './const';

import type { ParameterType } from './types';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Icon } from '~/client/core/ui/components/icon';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';

import styles from './styles.module.scss';

type Props = {
  value: number;
  type: ParameterType;
};

export const Parameter: React.FC<Props> = ({ type, value }) => {
  const language = useLanguage();

  return (
    <Section direction='vertical' gap={10} className={styles.wrapper}>
      <Text size='2xs' uppercase className={styles.label}>{language(`Parameter${type}`)}</Text>
      <Section direction="horizontal" align="center" gap={8}>
        <Icon type={PARAMETER_ICON[type]} className={styles.icon} />
        <div className={styles.amount}>{value}</div>
      </Section>
    </Section>
  );
};

