import { Logger } from '../logger';
import { SystemMessage } from '../system-message';
import { SystemMessageType } from '../system-message/types';

export class ErrorListener {
  public static listen() {
    window.addEventListener('error', (event: ErrorEvent) => {
      event.preventDefault();

      Logger.error('System error', event.error);
      this.renderError(event.message);
    });

    window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
      event.preventDefault();

      Logger.error('System error', event.reason);
      this.renderError(event.reason?.message);
    });
  }

  private static renderError(message?: string) {
    SystemMessage.render('system-error', {
      type: SystemMessageType.Error,
      sign: '☠️',
      title: 'System Error',
      message,
    });
  }
}
