import React from 'react';

import { Amount } from '..';

import IconWaves from './icons/waves.svg';

type Props = {
  value: number;
  size?: 's' | 'm' | 'l';
  animated?: boolean;
};

export const Waves: React.FC<Props> = ({ value, size, animated }) => (
  <Amount
    icon={IconWaves}
    color="#c190e6"
    value={value}
    size={size}
    animated={animated}
  />
);

