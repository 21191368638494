export enum RelayRequest {
  StartSearchingOpponent = 'Relay:StartSearchingOpponent',
  StopSearchingOpponent = 'Relay:StopSearchingOpponent',
  PlaySingle = 'Relay:PlaySingle',
  GenerateFriendId = 'Relay:GenerateFriendId',
  ClearFriendId = 'Relay:ClearFriendId',
  JoinFriendId = 'Relay:JoinFriendId',
}

export enum RelayMessage {
  JoinBattle = 'Relay:JoinBattle',
}
