import classNames from 'classnames';
import React from 'react';

import { CLOSE_KEY } from './const';

import { InputKeyboard } from '~/client/core/input/keyboard';
import { Interactive } from '~/client/core/ui/components/interactive';
import { useEvent } from '~/client/core/ui/hooks/use-event';

import styles from './styles.module.scss';

import IconClose from './icons/close.svg';

type Props = {
  onClick: VoidFunction;
  fixed?: boolean;
};

export const Close: React.FC<Props> = ({ fixed, onClick }) => {
  useEvent(InputKeyboard.onKeyDown, (event: KeyboardEvent) => {
    if (event.key === CLOSE_KEY) {
      event.preventDefault();
      onClick();
    }
  }, [onClick]);

  return (
    <Interactive onClick={onClick} className={classNames(styles.close, {
      [styles.fixed]: fixed,
    })}>
      <IconClose />
    </Interactive>
  );
};
