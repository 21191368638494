import cn from 'classnames';
import { useSchemaValue } from 'colyseus-schema-hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { AuthOffer } from './auth-offer';
import { Buttons } from './buttons';
import { Reward } from './reward';
import { OpponentWaitingState } from './state';
import { Waiting } from './waiting';
import { useScreen } from '../../../hooks/use-screen';
import { useSelfPlayerSchema } from '../../../hooks/use-self-player-schema';

import type { Battle } from '~/client/battle';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Section } from '~/client/core/ui/components/section';
import { AuthForm } from '~/client/core/user/ui/components/auth-form';
import { BattleMode } from '~/shared/battle/types';

import styles from './styles.module.scss';

export const StageFinished: React.FC = () => {
  const battle = useRoom<Battle>();
  const language = useLanguage();
  const { closeScreen } = useScreen();

  const player = useSelfPlayerSchema();
  const authorized = useSchemaValue(player, 'authorized');

  const [auth, setAuth] = useState(false);
  const [opponentWaiting, serOpponentWaiting] = useRecoilState(OpponentWaitingState);

  const winner = battle.state.winnerId === player.id;

  let title = language('YouWin');
  if (!winner) {
    if (battle.state.mode === BattleMode.Single) {
      if (player.live.health === 0) {
        title = language('YouDied');
      } else {
        title = language('BaseDestroyed');
      }
    } else {
      title = language('YouLose');
    }
  }

  const handleOpenAuth = useCallback(() => {
    setAuth(true);
  }, []);

  const handleCloseAuth = useCallback(() => {
    setAuth(false);
  }, []);

  useEffect(() => {
    closeScreen();
    return () => {
      serOpponentWaiting(false);
    };
  }, []);

  const body = () => {
    if (auth) {
      return <AuthForm onClose={handleCloseAuth} />;
    }

    if (opponentWaiting) {
      return <Waiting />;
    }

    return (
      <>
        {!authorized && (
          <AuthOffer onClick={handleOpenAuth} />
        )}
        <Section direction="vertical" align="center" gap={64} className={cn({
          [styles.offset]: !authorized,
        })}>
          <Section direction="vertical" align="center" gap={24}>
            <div className={styles.title}>{title}</div>
            <Reward />
          </Section>
          <Buttons />
        </Section>
      </>
    );
  };

  return (
    <div className={cn(styles.screen, {
      [styles.winner]: winner,
    })}>
      {body()}
    </div>
  );
};
