import cn from 'classnames';
import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { PageState } from '../pages/state';
import { PageType } from '../pages/types';

import { Close } from '~/client/core/ui/components/close';

import styles from './styles.module.scss';

type Props = {
  children: React.ReactNode;
  header?: React.ReactNode;
};

export const Sheet: React.FC<Props> = ({ children, header }) => {
  const [, setPage] = useRecoilState(PageState);

  const handleClickClose = useCallback(() => {
    setPage(PageType.Play);
  }, []);

  return (
    <div className={styles.overlay}>
      <div className={cn(styles.wrapper, {
        [styles.full]: !header,
      })}>
        <div className={styles.header}>
          <div className={styles.bar}>
            {header}
          </div>
          <Close onClick={handleClickClose} fixed={!header} />
        </div>
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </div>
  );
};
