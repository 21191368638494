import React from 'react';

import { DIFFICULT_ICON } from './const';
import { Kind } from '../kind';

import { Client } from '~/client/core/client';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRequest } from '~/client/core/request/ui/hooks/use-request';
import { Section } from '~/client/core/ui/components/section';
import { BattleDifficult } from '~/shared/battle/types';
import { RelayRequest } from '~/shared/relay/types';

export const SelectDifficult: React.FC = () => {
  const language = useLanguage();

  const playSingle = useRequest(RelayRequest.PlaySingle);

  const modes = Object.keys(BattleDifficult) as BattleDifficult[];
  const tutorialCompleted = Client.isTutorialCompleted();

  const handleClick = (difficult: BattleDifficult) => {
    if (playSingle.loading) {
      return;
    }

    playSingle.fetch({ difficult });
  };

  return (
    <Section direction="vertical" gap={10}>
      {modes.map((difficult) => (
        <Kind
          key={difficult}
          label={language(`Difficult${difficult}`)}
          description={language(`Difficult${difficult}Description`)}
          icon={DIFFICULT_ICON[difficult]}
          onClick={() => handleClick(difficult)}
          accent={difficult === BattleDifficult.Tutorial && !tutorialCompleted}
        />
      ))}
    </Section>
  );
};
