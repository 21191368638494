import type { BuildingSchema } from './entity/building/types';
import type { DroidSchema } from './entity/unit/npc/droid/types';
import type { TutorialSchema } from './tutorial/types';
import type { WaveSchema } from './wave/types';
import type { Schema, MapSchema } from '@colyseus/schema';
import type { MobSchema } from '~/shared/battle/entity/unit/npc/mob/types';
import type { PlayerSchema } from '~/shared/battle/entity/unit/player/types';
import type { TerrainSchema } from '~/shared/battle/terrain/types';

export interface BattleSchema extends Schema {
  readonly mode: BattleMode;
  readonly paused: boolean;
  readonly stage: BattleStage;
  readonly players: MapSchema<PlayerSchema>;
  readonly mobs: MapSchema<MobSchema>;
  readonly droids: MapSchema<DroidSchema>;
  readonly buildings: MapSchema<BuildingSchema>;
  readonly terrain: TerrainSchema;
  readonly winnerId: Nullable<string>;
  readonly wave: WaveSchema;
  readonly tutorial: Nullable<TutorialSchema>;
  readonly difficult: Nullable<BattleDifficult>;
}

export enum BattleMode {
  Online = 'Online',
  Single = 'Single',
}

export enum BattleDifficult {
  Tutorial = 'Tutorial',
  Normal = 'Normal',
  Hard = 'Hard',
}

export enum BattleStage {
  Preparing = 'Preparing',
  Started = 'Started',
  Finished = 'Finished',
  Terminated = 'Terminated',
}

/**
 * Messages
 */

export enum BattleMessage {
  Ping = 'Battle:Ping',
  TogglePause = 'Battle:TogglePause',
  Restart = 'Battle:Restart',
  DeclineRestart = 'Battle:DeclineRestart',
  RequestRestart = 'Battle:RequestRestart',
}

export type BattleMessagePayload = {
  [BattleMessage.Ping]: {
    stamp: number;
  };
  [BattleMessage.Restart]: {
    loadSave: boolean;
  };
  [BattleMessage.TogglePause]: {
    paused: boolean;
  };
  [BattleMessage.DeclineRestart]: void;
  [BattleMessage.RequestRestart]: void;
};
