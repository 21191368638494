import { Events } from 'make-event';
import { WebGLRenderer, AmbientLight, Clock, Scene as OriginScene, MathUtils } from 'three';

import { Camera } from './camera';
import { SCENE_CANVAS_ID } from './const';
import { SceneResolution } from './types';
import { Audio } from '../audio';
import { Device } from '../device';
import { Settings } from '../settings';
import { SettingsType } from '../settings/types';

import type { SceneConfig } from './types';

import { RATE } from '~/shared/core/const';

export class Scene extends OriginScene {
  public renderer: WebGLRenderer;

  public readonly camera: Camera = new Camera();

  protected paused: boolean = false;

  private readonly clock: Clock = new Clock();

  public readonly onUpdate = Events.make();
  public readonly onRender = Events.make();

  public deltaTime: number = 0;
  public delta: number = 1.0;
  private lastUpdateTimestamp: number = 0;

  public fps: number = 0.0;
  private fpsLoopTime: number = 0;
  private fpsTick: number = 0;
  public fpsLimit: number = 45;

  public readonly audio: Audio = new Audio();

  public resolution: SceneResolution = SceneResolution.Medium;

  private disposed: boolean = false;

  private backgroundColor: number;

  constructor({ backgroundColor, light }: SceneConfig) {
    super();

    this.backgroundColor = backgroundColor;

    this.addLight(light);

    const defaultResolution = Device.isMobile
      ? SceneResolution.High
      : SceneResolution.Medium;
    const resolution = Settings.getEnum(SettingsType.Resolution, SceneResolution, defaultResolution);
    this.createRender(resolution);

    const fpsLimit = Settings.getInteger(SettingsType.FpsLimit, 45);
    this.setFpsLimit(fpsLimit);

    const audioEffects = Settings.getBoolean(SettingsType.AudioEffects, true);
    this.audio.toggle(audioEffects);

    this.runRenderLoop();

    this.onScreenResize = this.onScreenResize.bind(this);
    Device.onScreenResize(this.onScreenResize);
  }

  public destroy(): void {
    this.disposed = true;

    this.remove();
    this.removeRenderer();
    this.audio.destroy();

    Device.onScreenResize.unsubscribe(this.onScreenResize);

    this.onUpdate.clear();
    this.onRender.clear();
  }

  private createRender(resolution: SceneResolution) {
    this.resolution = resolution;

    const canvas = document.createElement('canvas');
    canvas.id = SCENE_CANVAS_ID;
    Device.getWrapper().append(canvas);

    const antialias = resolution !== SceneResolution.Low;
    this.renderer = new WebGLRenderer({
      canvas,
      antialias,
    });

    const pixelRatio = resolution === SceneResolution.High && window.devicePixelRatio || 1;
    this.renderer.setPixelRatio(pixelRatio);
    this.renderer.setClearColor(this.backgroundColor);

    this.updateScreenSize();
    this.render();
  }

  public render() {
    this.renderer.render(this, this.camera);
  }

  private removeRenderer() {
    this.renderer.dispose();
    document.getElementById(SCENE_CANVAS_ID)?.remove();
  }

  private updateScreenSize() {
    const screenSize = Device.getScreenSize();
    this.renderer.setSize(screenSize.x, screenSize.y);
    this.camera.setSize(screenSize);
  }

  private addLight(color: number) {
    const ambientLight = new AmbientLight(color);
    this.add(ambientLight);
  }

  public setFpsLimit(limit: number): void {
    this.fpsLimit = MathUtils.clamp(limit, 30, 60);
  }

  public setResolution(resolution: SceneResolution) {
    this.removeRenderer();
    this.createRender(resolution);
  }

  private runRenderLoop() {
    let deltaStack = 0;

    const loop = () => {
      if (this.disposed) {
        return;
      }

      requestAnimationFrame(loop);

      const limit = 1 / this.fpsLimit;
      deltaStack += this.clock.getDelta();

      if (deltaStack > limit) {
        const now = performance.now();
        this.deltaTime = now - this.lastUpdateTimestamp;
        this.delta = this.deltaTime / RATE.UPDATE;

        if (!this.paused) {
          this.update();
        }

        this.measureFps();

        this.lastUpdateTimestamp = now;

        deltaStack %= limit;
      }
    };

    loop();
  }

  private measureFps() {
    this.fpsLoopTime += this.deltaTime;
    this.fpsTick++;

    if (this.fpsLoopTime >= 1000) {
      this.fps = this.fpsTick;
      this.fpsTick = 0;
      this.fpsLoopTime = 0;
    }
  }

  protected update() {
    this.onUpdate.invoke();
    this.camera.update();
    this.onRender.invoke();

    this.render();
  }

  private onScreenResize(): void {
    this.updateScreenSize();
    this.update();
  }

  public isFullscreen() {
    return Boolean(document.fullscreenElement);
  }

  public async toggleFullscreen(state: boolean) {
    try {
      if (state) {
        await document.documentElement.requestFullscreen();
      } else {
        await document.exitFullscreen();
      }

      this.update();
    } catch {
      //
    }
  }
}
