import React from 'react';

import { Section } from '../../section';
import { Text } from '../../text';

import type { SettingsType } from '~/client/core/settings/types';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';

import styles from './styles.module.scss';

type Props = {
  type: SettingsType;
  children: React.ReactNode;
};

export const Param: React.FC<Props> = ({ type, children }) => {
  const language = useLanguage();

  return (
    <>
      <Section direction='horizontal' className={styles.label}>
        <Text size="xl" bold uppercase>
          {language(type)}
        </Text>
      </Section>
      <Section direction='horizontal' align='left' className={styles.content}>
        {children}
      </Section>
    </>
  );
};
