import cn from 'classnames';
import React, { useRef, useLayoutEffect } from 'react';

import type { RenderItem } from '~/client/core/render-item';

import { Device } from '~/client/core/device';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { useEvent } from '~/client/core/ui/hooks/use-event';

import styles from './styles.module.scss';

type Props = {
  renderItem: RenderItem;
  className?: string;
  head?: React.ReactNode;
  body?: React.ReactNode;
};

export const Translator: React.FC<Props> = ({ renderItem, className, head, body }) => {
  const room = useRoom();

  const refContainer = useRef<HTMLDivElement>(null);

  useEvent(room.scene.onRender, () => {
    const container = refContainer.current;
    if (!container) {
      return;
    }

    if (!renderItem.visible) {
      container.style.display = 'none';
      return;
    }

    container.style.display = '';
    container.style.opacity = String(renderItem.getMaterial().opacity);

    const position = renderItem.getPositionOnScreen();
    container.style.left = `${position.x}px`;
    container.style.top = `${position.y}px`;

    const scale = Device.getScale() ** 0.75;
    const size = renderItem.getSizeOnScreen();
    container.style.transform = `translate(-50%, -100%) scale(${scale})`;
    container.style.width = `${size.x}px`;
    container.style.height = `${size.y}px`;
  }, []);

  useLayoutEffect(() => {
    if (refContainer.current && !renderItem.visible) {
      refContainer.current.style.display = 'none';
    }
  }, []);

  return (
    <div
      ref={refContainer}
      className={cn(
        styles.translator,
        className,
      )}
    >
      <div className={styles.wrapper} >
        {head && <div className={styles.head}>{head}</div>}
        {body && <div className={styles.body}>{body}</div>}
      </div>
    </div>
  );
};
