export const CUBE_GEOMETRY_FACES = [
  {
    // Top
    r: { x: -0.5 * Math.PI, y: 0, z: 0 },
    p: { x: 0, y: 0.5, z: 0 },
  },
  {
    // Left near
    r: { x: 0, y: 0, z: 0 },
    p: { x: 0, y: 0.0, z: 0.5 },
  },
  {
    // Right near
    r: { x: 0, y: 0.5 * Math.PI, z: 0 },
    p: { x: 0.5, y: 0.0, z: 0 },
  },
];
