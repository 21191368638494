import type { AudioType } from '~/client/core/audio/types';

import { useRoom } from '~/client/core/room/ui/hooks/use-room';

export function useAudio() {
  try {
    const room = useRoom();

    return {
      play: (type: AudioType) => {
        room.scene.audio.play2D(type);
      },
      stop: (type: AudioType) => {
        room.scene.audio.stop(type);
      },
    };
  } catch (e) {
    // ?
    return {
      play: (type: AudioType) => {
        void type;
      },
      stop: (type: AudioType) => {
        void type;
      },
    };
  }
}
