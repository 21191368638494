import React, { useEffect, useState } from 'react';

import { MENU_TOGGLE_KEY } from './const';
import { MenuPageType } from './types';
import { ScreenType } from '../../../../types';

import type { Battle } from '~/client/battle';

import { useScreen } from '~/client/battle/ui/hooks/use-screen';
import { Client } from '~/client/core/client';
import { InputKeyboard } from '~/client/core/input/keyboard';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Button } from '~/client/core/ui/components/button';
import { ButtonBack } from '~/client/core/ui/components/button/back';
import { Confirmation } from '~/client/core/ui/components/confirmation';
import { Section } from '~/client/core/ui/components/section';
import { SettingsPanel } from '~/client/core/ui/components/settings-panel';
import { useEvent } from '~/client/core/ui/hooks/use-event';
import { BattleMode } from '~/shared/battle/types';
import { RoomType } from '~/shared/core/room/types';

import styles from './styles.module.scss';

export const MenuScreen: React.FC = () => {
  const battle = useRoom<Battle>();
  const language = useLanguage();
  const { screen, openScreen, closeScreen } = useScreen();

  const [page, setPage] = useState<MenuPageType>(MenuPageType.Main);

  const visible = screen === ScreenType.Menu;

  const handleClickBack = () => {
    setPage(MenuPageType.Main);
  };

  const handleClickLeave = () => {
    Client.joinRoomByType(RoomType.Relay);
  };

  const handleClickRestart = () => {
    Client.setLoading();
    battle.restart(false);
    closeScreen();
  };

  useEvent(InputKeyboard.onKeyDown, (event: KeyboardEvent) => {
    if (event.code !== MENU_TOGGLE_KEY) {
      return;
    }

    event.preventDefault();

    if (screen) {
      closeScreen();
    } else {
      openScreen(ScreenType.Menu);
      setPage(MenuPageType.Main);
    }
  }, [screen]);

  useEffect(() => {
    battle.scene.audio.muffle(visible);
  }, [visible]);

  return visible && (() => {
    switch (page) {
      case MenuPageType.Main:
        return (
          <Section direction="vertical" gap={8}>
            <Button
              onClick={closeScreen}
              view="accent"
            >
              {language('Continue')}
            </Button>
            {battle.state.mode === BattleMode.Single && (
              <Button onClick={() => setPage(MenuPageType.Restart)}>
                {language('Restart')}
              </Button>
            )}
            <Button onClick={() => setPage(MenuPageType.Settings)}>
              {language('Settings')}
            </Button>
            <br />
            <Button
              onClick={() => setPage(MenuPageType.Leave)}
              view="negative"
            >
              {language('MainMenu')}
            </Button>
          </Section>
        );
      case MenuPageType.Settings:
        return (
          <div className={styles.settings}>
            <Section direction="vertical" align="center" gap={48}>
              <SettingsPanel />
              <ButtonBack onClick={handleClickBack} />
            </Section>
          </div>
        );
      case MenuPageType.Restart:
        return (
          <Confirmation
            text={language('RestartConfirm')}
            onConfirm={handleClickRestart}
            onDecline={handleClickBack}
          />
        );
      case MenuPageType.Leave:
        return (
          <Confirmation
            text={language('LeaveConfirm')}
            onConfirm={handleClickLeave}
            onDecline={handleClickBack}
          />
        );
    }
  })();
};
