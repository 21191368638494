import cn from 'classnames';
import { useSchemaValue } from 'colyseus-schema-hooks';
import React, { useEffect } from 'react';

import { formatTime } from './utils/format-time';

import type { Battle } from '~/client/battle';

import { AudioType } from '~/client/core/audio/types';
import { useAudio } from '~/client/core/audio/ui/hooks/use-audio';
import { Client } from '~/client/core/client';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { SDK } from '~/client/core/sdk';
import { SDKAdType } from '~/client/core/sdk/types';
import { Interactive } from '~/client/core/ui/components/interactive';
import { useNextEffect } from '~/client/core/ui/hooks/use-next-effect';
import { BattleDifficult } from '~/shared/battle/types';

import styles from './styles.module.scss';

import IconPause from './icons/pause.svg';
import IconSkip from './icons/skip.svg';

export const WaveInfoPanel: React.FC = () => {
  const battle = useRoom<Battle>();
  if (!battle.wave) {
    return null;
  }

  const audio = useAudio();
  const language = useLanguage();

  const battlePaused = useSchemaValue(battle.state, 'paused');

  const going = useSchemaValue(battle.wave.schema, 'going');
  const number = useSchemaValue(battle.wave.schema, 'number');
  const timeleft = useSchemaValue(battle.wave.schema, 'timeleft');
  const mobsLeft = useSchemaValue(battle.wave.schema, 'mobsLeft');
  const paused = useSchemaValue(battle.wave.schema, 'paused');

  const timeleftSeconds = Math.ceil(timeleft / 1000);
  const tutorialCompleted = (
    number === 6 &&
    battle.state.difficult === BattleDifficult.Tutorial
  );

  const handleClickSkip = () => {
    battle.wave?.skipTimeleft();
  };

  useNextEffect(async () => {
    if (SDK.enabled && (number - 1) % 5 === 0) {
      battle.setPause(true);
      await SDK.showAd(SDKAdType.Commercial);
      battle.setPause(false);
    }
  }, [number]);

  useEffect(() => {
    if (tutorialCompleted) {
      Client.completeTutorial();
    }
  }, [tutorialCompleted]);

  useEffect(() => {
    if (!paused && timeleftSeconds <= 3 && timeleftSeconds > 0) {
      audio.play(AudioType.WaveTick);
    }
  }, [paused, timeleftSeconds]);

  return (
    <div className={styles.container}>
      {paused ? (
        <div className={cn(styles.frame, styles.paused)}>
          <IconPause className={styles.icon} />
        </div>
      ) : (
        <div className={cn(styles.frame, styles.number, {
          [styles.going]: going,
        })}>
          {number}
        </div>
      )}
      <div className={styles.info}>
        {going ? (
          <>
            <div className={styles.value}>
              {mobsLeft.toString().padStart(2, '0')}
            </div>
            <div className={styles.label}>
              {language('EnemiesLeft')}
            </div>
          </>
        ) : (
          <>
            <div className={styles.value}>{formatTime(timeleft)}</div>
            <div className={styles.label}>
              {language('UntilWaveStart')}
            </div>
          </>
        )}
      </div>
      {!going && !paused && (
        <Interactive
          onClick={handleClickSkip}
          disabled={battlePaused}
          className={cn(styles.skip, {
            [styles.active]: !battlePaused,
          })}
        >
          <IconSkip className={styles.icon} />
        </Interactive>
      )}
    </div>
  );
};
