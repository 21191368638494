import cn from 'classnames';
import React from 'react';

import { Text } from '~/client/core/ui/components/text';

import styles from './styles.module.scss';

type Props = {
  size?: 'm' | 'l';
  view?: 'transparent' | 'default' | 'light';
  label: string;
  children: React.ReactNode;
};

export const ValueCard: React.FC<Props> = ({ size = 'm', view = 'default', label, children }) => (
  <div className={cn(styles.container, styles[`view-${view}`], styles[`size-${size}`])}>
    <Text size='s' bold className={styles.label}>{label}</Text>
    <div className={styles.value}>
      {children}
    </div>
  </div>
);
