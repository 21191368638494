import type { SkillSchema, SkillVariant } from './skill/types';
import type { UpgradeVariant } from './upgrades/types';
import type { LiveSchema } from '../../live/types';
import type { UnitMessagePayload, UnitSchema } from '../types';
import type { MapSchema } from '@colyseus/schema';
import type { Vector2Like, Vector3Like } from 'three';

export interface PlayerSchema extends UnitSchema {
  readonly connected: boolean;
  readonly resources: number;
  readonly ammo: number;
  readonly experience: number;
  readonly experienceProgress: number;
  readonly level: number;
  readonly live: LiveSchema;
  readonly upgrades: MapSchema<number, UpgradeVariant>;
  readonly tickMove: number;
  readonly mobsCount: number;
  readonly skills: MapSchema<SkillSchema, SkillVariant>;
  readonly score: number;
  readonly crystals: number;
  readonly stamina: number;
  readonly bot: boolean;
  readonly builderFlowIndex: number;
  readonly visibleDistance: number;
  readonly name: Nullable<string>;
  readonly authorized: boolean;
}

export enum PlayerCheat {
  GiveResources = 'RICH',
  GiveExperience = 'GOUP',
  Godmode = 'HOLY',
  BoostSpeed = 'VELO',
  NextWave = 'NEXT',
}

/**
 * Messages
 */

export enum PlayerMessage {
  Attack = 'Player:Attack',
  DisplayAttack = 'Player:DisplayAttack',
  LevelUp = 'Player:LevelUp',
  Move = 'Player:Move',
  ChangeMovingVector = 'Player:ChangeMovingVector',
  Upgrade = 'Player:Upgrade',
  UseCheatCode = 'Player:UseCheatCode',
  UseSkill = 'Player:UseSkill',
  PickupCrystal = 'Player:PickupCrystal',
}

export type PlayerMessagePayload = UnitMessagePayload & {
  [PlayerMessage.Attack]: void;
  [PlayerMessage.Move]: {
    tick: number;
    delta: number;
  };
  [PlayerMessage.LevelUp]: void;
  [PlayerMessage.ChangeMovingVector]: {
    vector: Vector2Like;
  };
  [PlayerMessage.UseCheatCode]: {
    code: PlayerCheat;
  };
  [PlayerMessage.UseSkill]: {
    variant: SkillVariant;
  };
  [PlayerMessage.Upgrade]: {
    variant: UpgradeVariant;
  };
  [PlayerMessage.PickupCrystal]: {
    resources: number;
    position: Vector3Like;
  };
};
