import { Shape } from '..';

import type { CubeConfig } from './types';
import type { Scene } from '../../../scene';

import { CubeGeometry } from '~/shared/core/geometry/cube';

const _CubeGeometry = CubeGeometry.make();

export class Cube extends Shape {
  constructor(scene: Scene, config: CubeConfig) {
    super(scene, {
      ...config,
      geometry: _CubeGeometry,
    });
  }
}
