import React, { useEffect, useRef } from 'react';

import type { Battle } from '../..';

import { useRoom } from '~/client/core/room/ui/hooks/use-room';

import styles from './styles.module.scss';

export const Minimap: React.FC = () => {
  const { minimap } = useRoom<Battle>();

  const refCanvas = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!refCanvas.current) {
      return;
    }

    minimap.enable(refCanvas.current);
    return () => {
      minimap.disable();
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <canvas ref={refCanvas} />
    </div>
  );
};
