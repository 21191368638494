//@ts-nocheck

import { BufferAttribute, BufferGeometry } from 'three';

export function mergeGeometries(geometries: BufferGeometry[]): BufferGeometry {
  const attributesUsed = new Set(Object.keys(geometries[0].attributes));
  const morphAttributesUsed = new Set(Object.keys(geometries[0].morphAttributes));

  const attributes: Record<string, any[]> = {};
  const morphAttributes: Record<string, any[]> = {};

  const morphTargetsRelative = geometries[0].morphTargetsRelative;
  const indexed = geometries[0].index !== null;

  const mergedGeometry = new BufferGeometry();

  geometries.forEach((geometry) => {
    let attributesCount = 0;

    if (indexed !== (geometry.index !== null)) {
      throw Error('All geometries must have compatible attributes. Make sure index attribute exists among all geometries, or in none of them');
    }

    for (const name in geometry.attributes) {
      if (!attributesUsed.has(name)) {
        throw Error(`All geometries must have compatible attributes. Make sure '${name}' attribute exists among all geometries, or in none of them`);
      }

      if (!attributes[name]) {
        attributes[name] = [];
      }

      attributes[name].push(geometry.attributes[name]);
      attributesCount++;
    }

    if (attributesCount !== attributesUsed.size) {
      throw Error('Make sure all geometries have the same number of attributes');
    }

    if (morphTargetsRelative !== geometry.morphTargetsRelative) {
      throw Error('morphTargetsRelative must be consistent throughout all geometries');
    }

    for (const name in geometry.morphAttributes) {
      if (!morphAttributesUsed.has(name)) {
        throw Error('morphAttributes must be consistent throughout all geometries');
      }

      if (!morphAttributes[name]) {
        morphAttributes[name] = [];
      }

      morphAttributes[name].push(geometry.morphAttributes[name]);
    }
  });

  if (indexed) {
    let indexOffset = 0;
    const mergedIndex: number[] = [];

    geometries.forEach(({ index, attributes }) => {
      if (index) {
        for (let j = 0; j < index.count; ++j) {
          mergedIndex.push(index.getX(j) + indexOffset);
        }
      }
      indexOffset += attributes.position.count;
    });

    mergedGeometry.setIndex(mergedIndex);
  }

  for (const name in attributes) {
    const mergedAttribute = mergeAttributes(attributes[name]);
    if (!mergedAttribute) {
      throw Error(`Failed to merge the '${name}' attribute`);
    }

    mergedGeometry.setAttribute(name, mergedAttribute);
  }

  for (const name in morphAttributes) {
    const numMorphTargets = morphAttributes[name][0].length;
    if (numMorphTargets === 0) break;

    mergedGeometry.morphAttributes = mergedGeometry.morphAttributes ?? {};
    mergedGeometry.morphAttributes[name] = [];

    for (let i = 0; i < numMorphTargets; ++i) {
      const morphAttributesToMerge = morphAttributes[name].map((attr) => attr[i]);
      const mergedMorphAttribute = mergeAttributes(morphAttributesToMerge);
      mergedGeometry.morphAttributes[name].push(mergedMorphAttribute);
    }
  }

  return mergedGeometry;
}

export function mergeAttributes(attributes: BufferAttribute[]): BufferAttribute {
  let TypedArray;
  let itemSize: number;
  let normalized: boolean;
  let gpuType = -1;
  let arrayLength = 0;

  for (let i = 0; i < attributes.length; ++i) {
    const attribute = attributes[i];

    if (TypedArray === undefined) TypedArray = attribute.array.constructor;
    if (TypedArray !== attribute.array.constructor) {
      throw Error('BufferAttribute.array must be of consistent array types across matching attributes');
    }

    if (itemSize === undefined) itemSize = attribute.itemSize;
    if (itemSize !== attribute.itemSize) {
      throw Error('BufferAttribute.itemSize must be consistent across matching attributes');
    }

    if (normalized === undefined) normalized = attribute.normalized;
    if (normalized !== attribute.normalized) {
      throw Error('BufferAttribute.normalized must be consistent across matching attributes');
    }

    if (gpuType === -1) gpuType = attribute.gpuType;
    if (gpuType !== attribute.gpuType) {
      throw Error('BufferAttribute.gpuType must be consistent across matching attributes');
    }

    arrayLength += attribute.count * itemSize;
  }

  const array = new TypedArray(arrayLength);
  const result = new BufferAttribute(array, itemSize, normalized);
  let offset = 0;

  for (let i = 0; i < attributes.length; ++i) {
    const attribute = attributes[i];
    if (attribute.isInterleavedBufferAttribute) {
      const tupleOffset = offset / itemSize;
      for (let j = 0, l = attribute.count; j < l; j++) {
        for (let c = 0; c < itemSize; c++) {
          const value = attribute.getComponent(j, c);
          result.setComponent(j + tupleOffset, c, value);
        }
      }
    } else {
      array.set(attribute.array, offset);
    }

    offset += attribute.count * itemSize;
  }

  if (gpuType) {
    result.gpuType = gpuType;
  }

  return result;
}
