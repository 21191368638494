import { Building } from '../..';
import { ShotFactory } from '../../../shot/factory';

import type { Shot } from '../../../shot';
import type { Battle } from '~/client/battle';
import type { BuildingBaseSchema } from '~/shared/battle/entity/building/variants/base/types';

import { ModelType } from '~/client/core/assets/types';

import './resources';

export class BuildingBase extends Building {
  declare readonly schema: BuildingBaseSchema;

  private readonly shot: Shot;

  constructor(battle: Battle, schema: BuildingBaseSchema) {
    super(battle, {
      model: ModelType.BuildingBase,
    }, schema);

    this.shot = ShotFactory.create(battle, schema.weapon.shot);
  }

  override destroy(): void {
    super.destroy();

    this.shot.destroy();
  }
}
