import { PlaneGeometry } from 'three';

import { CUBE_GEOMETRY_FACES } from './const';
import { mergeGeometries } from '../merge';

/**
 * Simplified cube geometry without invisible faces
 */
export class CubeGeometry {
  public static make(indexes?: number[]) {
    let faces: PlaneGeometry[] = [];
    if (indexes) {
      faces = indexes.map((index) => this.makeFace(index));
    } else {
      for (let i = 0; i < CUBE_GEOMETRY_FACES.length; i++) {
        faces.push(this.makeFace(i));
      }
    }

    return mergeGeometries(faces);
  }

  private static makeFace(index: number) {
    const { p, r } = CUBE_GEOMETRY_FACES[index];
    const face = new PlaneGeometry();

    face.rotateX(r.x);
    face.rotateY(r.y);
    face.rotateZ(r.z);
    face.translate(p.x, p.y, p.z);

    return face;
  }
}
