import type { ModelType } from '~/client/core/assets/types';

export type BuildingVariantConfig = {
  model: ModelType;
};

export enum BuildingPanelVisible {
  None = 'None',
  Compact = 'Compact',
  Full = 'Full',
}
