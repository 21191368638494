import { Building } from '../..';

import type { BuildingVariantConfig } from '../../types';
import type { Battle } from '~/client/battle';
import type { Shot } from '~/client/battle/entity/shot';
import type { BuildingTowerSchema } from '~/shared/battle/entity/building/variants/tower/types';

import { ShotFactory } from '~/client/battle/entity/shot/factory';

export class BuildingTower extends Building {
  declare readonly schema: BuildingTowerSchema;

  private readonly shot: Shot;

  constructor(battle: Battle, config: BuildingVariantConfig, schema: BuildingTowerSchema) {
    super(battle, config, schema);

    this.shot = ShotFactory.create(battle, schema.weapon.shot);
  }

  override destroy(): void {
    super.destroy();

    this.shot.destroy();
  }
}
