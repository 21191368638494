export class Request {
  public static async get<T>(path: string) {
    return fetch(location.origin + path).then((res) => res.json() as T);
  }

  public static async post(path: string, data: any) {
    return fetch(location.origin + path, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
  }

  public static getResponseError(error?: Error) {
    const message = error?.message;
    if (!message) {
      return 'Unknown error';
    }

    if (message === 'Failed to fetch') {
      return 'Network error';
    }

    return message.replace(/^.*\d+:\s/, '');
  }
}
