import React, { useEffect } from 'react';

import { Resources } from '../resources';
import { TranslatorManual } from '../translator/manual';

import type { CrystalPickupData } from './types';

import { useSelfPlayer } from '~/client/battle/ui/hooks/use-self-player';
import { useEvent } from '~/client/core/ui/hooks/use-event';
import { useStateMap } from '~/client/core/ui/hooks/use-state-map';
import { Utils } from '~/shared/core/utils';

import styles from './styles.module.scss';

export const CrystalNotice: React.FC = () => {
  const player = useSelfPlayer();

  const notices = useStateMap<string, CrystalPickupData>();

  useEvent(player.onCrystalPickup, (payload) => {
    const uuid = Utils.uuid();
    notices.set(uuid, {
      ...payload,
      timeout: setTimeout(() => {
        notices.delete(uuid);
      }, 1500),
    });
  }, []);

  useEffect(() => {
    return () => {
      notices.forEach((key, notice) => {
        clearTimeout(notice.timeout);
      });
    };
  }, []);

  return notices.map((key, notice) => (
    <TranslatorManual key={key} position={notice.position}>
      <Resources value={notice.resources} className={styles.notice} />
    </TranslatorManual>
  ));
};
