import { useSchemaValue } from 'colyseus-schema-hooks';
import React, { useEffect } from 'react';

import { CrystalNotice } from './components/crystal-notice';
import { Entities } from './components/entities';
import { BATTLE_STAGE_COMPONENT_MAP } from './const';
import { useScreen } from './hooks/use-screen';
import { BuildControls } from '../builder/ui/components/build-controls';

import type { ScreenType } from './types';
import type { Battle } from '..';

import { Device } from '~/client/core/device';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Storage } from '~/client/core/storage';
import { useRelativeScreen } from '~/client/core/ui/hooks/use-relative-screen';
import { BattleStage } from '~/shared/battle/types';

import styles from './styles.module.scss';

export const BattleScreen: React.FC = () => {
  const { state } = useRoom<Battle>();
  const { openScreen } = useScreen();

  const stage = useSchemaValue(state, 'stage') ?? BattleStage.Preparing;
  const StageComponent = BATTLE_STAGE_COMPONENT_MAP[stage];

  const refScreen = useRelativeScreen();

  useEffect(() => {
    const savedScreen = Storage.get<ScreenType>('Screen');
    if (savedScreen) {
      openScreen(savedScreen);
    }
  }, []);

  return (
    <>
      <div ref={refScreen} className={styles.screen}>
        {StageComponent && <StageComponent />}
      </div>
      {stage === BattleStage.Started && (
        <CrystalNotice />
      )}
      {stage !== BattleStage.Finished && (
        <>
          <Entities />
          {Device.isMobile && (
            <BuildControls />
          )}
        </>
      )}
    </>
  );
};
