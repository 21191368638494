import {
  BufferGeometry,
  DirectionalLight,
  Float32BufferAttribute,
  MathUtils,
  Points,
  Vector2,
  Vector3,
} from 'three';

import {
  RELAY_SCENE_BACKGROUND_COLOR,
  RELAY_SCENE_LIGHT,
  RELAY_SCENE_SPACE_DUST_COUNT,
  RELAY_SCENE_SPACE_DUST_LAYERS,
  RELAY_SCENE_SPACE_DUST_SPREAD,
} from './const';

import type { Relay } from '..';

import { Assets } from '~/client/core/assets';
import { MaterialType } from '~/client/core/assets/materials/types';
import { ModelType } from '~/client/core/assets/types';
import { Client } from '~/client/core/client';
import { Device } from '~/client/core/device';
import { InputMouse } from '~/client/core/input/mouse';
import { Model } from '~/client/core/render-item/model';
import { Scene } from '~/client/core/scene';
import { DroidVariant } from '~/shared/battle/entity/unit/npc/droid/types';
import { InventoryItemType } from '~/shared/core/inventory/item/types';
import { InventoryUtils } from '~/shared/core/inventory/utils';

import './resources';

export class RelayScene extends Scene {
  private readonly relay: Relay;

  private models: Set<Model> = new Set();

  private cameraLookUp: Vector3 = new Vector3();

  private droid: Model;

  constructor(relay: Relay) {
    super({
      light: RELAY_SCENE_LIGHT,
      backgroundColor: RELAY_SCENE_BACKGROUND_COLOR,
    });

    this.relay = relay;

    this.setCameraPosition();

    this.addLocalLight();
    this.addDecorationModels();
    this.addSpaceDust();

    this.onMouseMove = this.onMouseMove.bind(this);
    InputMouse.onMouseMove(this.onMouseMove);
  }

  override destroy(): void {
    super.destroy();

    this.models.forEach((model) => {
      model.destroy();
    });

    this.models.clear();

    InputMouse.onMouseMove.unsubscribe(this.onMouseMove);
  }

  override update() {
    this.animateSpaceDust();
    this.updateCameraLookUp();

    super.update();
  }

  private onMouseMove(event: MouseEvent) {
    const screenSize = Device.getScreenSize();
    const halfSize = new Vector2().copy(screenSize).divideScalar(2);

    const point = {
      x: ((event.clientX - halfSize.x) / halfSize.x) * 0.1,
      y: ((event.clientY - halfSize.y) / halfSize.y) * 0.1,
    };

    this.cameraLookUp.set(0 + point.x, 1.2 - point.y, 9);
  }

  private updateCameraLookUp(): void {
    this.camera.position.lerp(this.cameraLookUp, 0.025);
  }

  private setCameraPosition() {
    this.cameraLookUp.set(0, 1.2, 9);
    this.camera.position.set(0, 1.2, 6);
    this.camera.lookAt(new Vector3(0, 1.2, 0));
  }

  private addLocalLight() {
    const directionalLight = new DirectionalLight(0xffffff, 0.7);
    directionalLight.position.set(0, 10, 0);
    this.add(directionalLight);
  }

  public addPlayerModel() {
    const player = new Model(this, {
      model: ModelType.Player,
      material: MaterialType.Unit,
      position: { x: 0.5, y: 0, z: 1 },
    });

    player.changeMaterial('BodyMesh', MaterialType.Self);
    player.animator.play('idle', {
      timeScale: 0.75,
    });

    this.models.add(player);
  }

  public addDroidModel() {
    const user = this.relay.state.users.get(Client.sessionId);

    const getVariant = () => (
      (user && InventoryUtils.getItem(user.inventory, InventoryItemType.Droid)) ??
      DroidVariant.Combat
    );

    let currentVariant = getVariant();

    const create = () => {
      this.droid = new Model(this, {
        model: ModelType[`Droid${currentVariant}`],
        material: MaterialType.Unit,
        position: { x: -0.05, y: 1.2, z: -1 },
      });

      this.droid.setRotation({ x: 0, y: -Math.PI / 8, z: 0 });

      this.droid.changeMaterial('Cube_2', MaterialType.Self);
      this.droid.animator.play('fly', {
        timeScale: 0.1,
      });

      this.models.add(this.droid);
    };

    user?.inventory.slots.onChange(() => {
      const newVariant = getVariant();
      if (currentVariant !== newVariant) {
        currentVariant = newVariant;
        this.models.delete(this.droid);
        this.droid.destroy();
        create();
      }
    });

    create();
  }

  private addDecorationModels() {
    this.models.add(
      new Model(this, {
        model: ModelType.Tree1,
        material: MaterialType.Decoration,
        position: { x: 2, y: -0.4, z: -8 },
        rotation: { x: 0, y: Math.PI, z: 0 },
        scale: 0.8,
      }),
    );

    this.models.add(
      new Model(this, {
        model: ModelType.Tree2,
        material: MaterialType.Decoration,
        position: { x: -0.2, y: -0.6, z: -8 },
        rotation: { x: 0.4, y: -0.2, z: 0 },
        scale: 0.6,
      }),
    );

    this.models.add(
      new Model(this, {
        model: ModelType.Tree2,
        material: MaterialType.Decoration,
        position: { x: 0.8, y: -0.4, z: -8 },
        rotation: { x: 0, y: -Math.PI / 4, z: 0 },
        scale: 0.8,
      }),
    );
  }

  private addSpaceDust() {
    const spread = () => (
      Math.round(MathUtils.randFloatSpread(RELAY_SCENE_SPACE_DUST_SPREAD))
    );

    const vertices = [];
    for (let i = 0; i < RELAY_SCENE_SPACE_DUST_COUNT * 3; i ++) {
      vertices.push(
        spread(),
        spread(),
        spread(),
      );
    }

    const geometry = new BufferGeometry();
    geometry.setAttribute('position', new Float32BufferAttribute(vertices, 3));

    RELAY_SCENE_SPACE_DUST_LAYERS.forEach((depth, i) => {
      // @ts-ignore
      const material = Assets.getMaterial(MaterialType[`Dust${i}`]);
      const particles = new Points(geometry, material);
      particles.rotation.x = Math.random() * Math.PI * 2;
      particles.rotation.y = Math.random() * Math.PI * 2;
      particles.rotation.z = Math.random() * Math.PI * 2;
      particles.position.set(0, 0, depth);

      this.add(particles);
    });
  }

  private animateSpaceDust() {
    for (let i = 0; i < this.children.length; i ++) {
      const object = this.children[i];
      if (object instanceof Points) {
        object.rotation.y += 0.0001;
      }
    }
  }
}
