import React from 'react';

import { Key } from './key';
import { KeyAction } from './key/types';
import { Swipe } from './swipe';
import { SwipeDirection } from './swipe/types';
import { Text } from './text';
import { HintIcon } from '../icon';

import { Device } from '~/client/core/device';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Section } from '~/client/core/ui/components/section';
import { BUILDER_TUTORIAL_TARGET } from '~/shared/battle/builder/const';
import { TutorialStep } from '~/shared/battle/tutorial/types';

import IconAttack from './icons/attack.svg';
import IconBuild from './icons/build.svg';
import IconUpgrade from './icons/upgrade.svg';

type Props = {
  step: TutorialStep;
};

export const TutorialContent: React.FC<Props> = ({ step }) => {
  const language = useLanguage();

  const text = step && language(`Hint${step}`);
  let icon: Nullable<SVGIcon> = null;

  const targetBuilding = BUILDER_TUTORIAL_TARGET[step];
  if (targetBuilding) {
    icon = IconBuild;
  } else if (step.includes('Upgrade')) {
    icon = IconUpgrade;
  } else if (step.includes('Attack')) {
    icon = IconAttack;
  }

  const body = () => {
    switch (step) {
      case TutorialStep.Attack:
        return Device.isDesktop && (
          <Key action={KeyAction.Press} target='SPACE'>
            {text}
          </Key>
        );
      case TutorialStep.UpgradeGenerator:
      case TutorialStep.UpgradeTowerFire:
        return (
          <Text>
            {text}
          </Text>
        );
      case TutorialStep.UpgradePlayer:
        return Device.isMobile ? (
          <Swipe direction={SwipeDirection.Up}>
            {text}
          </Swipe>
        ) : (
          <Key action={KeyAction.Hold} target={Device.isMacOS ? 'OPTION' : 'ALT'}>
            {text}
          </Key>
        );
      case TutorialStep.BuildAmmunition:
      case TutorialStep.BuildSecondAmmunition:
      case TutorialStep.BuildTowerFire:
      case TutorialStep.BuildTowerFrost:
      case TutorialStep.BuildGenerator:
      case TutorialStep.BuildSecondGenerator:
      case TutorialStep.BuildRadar:
        return Device.isMobile ? (
          <Swipe direction={SwipeDirection.Left}>
            {text}
          </Swipe>
        ) : (
          <Key action={KeyAction.Hold} target='SHIFT'>
            {text}
          </Key>
        );
    }
  };

  return (
    <Section direction='horizontal' align='center' gap={24}>
      {icon && Device.isDesktop && (
        <HintIcon icon={icon} />
      )}
      {body()}
    </Section>
  );
};
