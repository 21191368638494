export enum AudioMode {
  Track2D = 'Track2D',
  Track3D = 'Track3D',
}

export type AudioConfig = {
  buffer: AudioBuffer[];
  mode: AudioMode;
  loop?: boolean;
  limit?: number;
  volume?: number;
};

export type AudioSourceConfig = {
  path: string | string[];
  mode: AudioMode;
  loop?: boolean;
  limit?: number;
  volume: number;
};

export enum AudioType {
  // Interface
  Error = 'Error',
  Click = 'Click',
  Alert = 'Alert',

  // Battle
  Win = 'Win',
  GameOver = 'GameOver',
  Join = 'Join',

  // Wave
  WaveStart = 'WaveStart',
  WaveComplete = 'WaveComplete',
  WaveTick = 'WaveTick',

  // Builder
  Build = 'Build',

  // Buildings
  BuildingHit = 'BuildingHit',
  BuildingUpgrade = 'BuildingUpgrade',
  BuildingRepair = 'BuildingRepair',
  BuildingBreak = 'BuildingBreak',

  // Player
  PlayerUseSkill = 'PlayerUseSkill',
  PlayerStep = 'PlayerStep',
  PlayerLevelUp = 'PlayerLevelUp',
  PlayerUpgrade = 'PlayerUpgrade',
  PlayerAttack = 'PlayerAttack',
  PlayerHit = 'PlayerHit',

  // Crystal
  Pickup = 'Pickup',

  // Mobs
  MobFrost = 'MobFrost',
  MobDead = 'MobDead',
  MobAttack = 'MobAttack',
  MobExtraAttack = 'MobExtraAttack',
  MobBossRoar = 'MobBossRoar',
  MobHitFire = 'MobHitFire',
  MobHitPunch = 'MobHitPunch',
  MobHit = 'MobHit',

  // Shots
  ShotFrost = 'ShotFrost',
  ShotLaser = 'ShotLaser',
  ShotFire = 'ShotFire',
  ShotElectro = 'ShotElectro',
  ShotSimple = 'ShotSimple',

  // Terrain
  Ambient = 'Ambient',
}
