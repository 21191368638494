import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { useUserData } from '../../../hooks/use-user-data';
import { PageState } from '../../pages/state';
import { PageType } from '../../pages/types';

import { Crystals } from '~/client/core/ui/components/amount/crystals';
import { Score } from '~/client/core/ui/components/amount/score';
import { Icon } from '~/client/core/ui/components/icon';
import { Interactive } from '~/client/core/ui/components/interactive';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';

import styles from './styles.module.scss';

import IconUser from './icons/user.svg';

export const UserInfo: React.FC = () => {
  const name = useUserData('name');
  const crystals = useUserData('crystals');
  const score = useUserData('score');

  const [, setPage] = useRecoilState(PageState);

  const handleClick = useCallback(() => {
    setPage(PageType.Profile);
  }, []);

  return (
    <Interactive onClick={handleClick} className={styles.container}>
      <Section direction="horizontal" align='center' gap={8}>
        <Icon type={IconUser} size={18} className={styles.icon} />
        <Text size="m" bold nowrap>
          {name}
        </Text>
      </Section>
      <Section direction="horizontal" gap={16}>
        <Crystals value={crystals} />
        <Score value={score} />
      </Section>
    </Interactive>
  );
};
