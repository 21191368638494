import type { Vector2 } from 'three';

export enum InputTouchSwipeDirection {
  Left = 'Left',
  Right = 'Right',
  Up = 'Up',
  Down = 'Down',
}

export type InputTouchSwipeEvent = {
  position: Vector2;
  direction: InputTouchSwipeDirection;
};
