import React from 'react';

import { Sheet } from '../../sheet';

import { AuthForm } from '~/client/core/user/ui/components/auth-form';

export const PageAuth: React.FC = () => (
  <Sheet>
    <AuthForm />
  </Sheet>
);

