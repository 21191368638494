import { PageType } from '../pages/types';

import type { MenuItem } from './types';

import IconInventory from './icons/inventory.svg';
import IconLeaderboard from './icons/leaderboard.svg';
import IconPlay from './icons/play.svg';

export const MENU_ITEMS: MenuItem[] = [
  {
    page: PageType.Play,
    icon: IconPlay,
  },
  {
    page: PageType.Inventory,
    icon: IconInventory,
    authorizedOnly: true,
  },
  {
    page: PageType.Leaderboard,
    icon: IconLeaderboard,
  },
];
