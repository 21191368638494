import React from 'react';
import { useRecoilState } from 'recoil';

import { ParamBoolean } from './params/param-boolean';
import { ParamEnum } from './params/param-enum';
import { ParamInteger } from './params/param-integer';
import { ParamSelect } from './params/param-select';

import { Language } from '~/client/core/language';
import { LanguageType } from '~/client/core/language/types';
import { LanguageState } from '~/client/core/language/ui/state';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { SceneResolution } from '~/client/core/scene/types';
import { Settings } from '~/client/core/settings';
import { SettingsType } from '~/client/core/settings/types';

import styles from './styles.module.scss';

export const SettingsPanel: React.FC = () => {
  const room = useRoom();

  const [language, setLanguage] = useRecoilState(LanguageState);

  const handleChangeLanguage = (value: string) => {
    setLanguage(value as LanguageType);
    Language.setDocumentTag(value as LanguageType);
  };

  const handleChangeFpsLimit = (value: number) => {
    room.scene.setFpsLimit(value);
  };

  const handleChangeResolution = (value: SceneResolution) => {
    room.scene.setResolution(value);
  };

  const handleChangeFullscreen = (value: boolean) => {
    room.scene.toggleFullscreen(value);
  };

  const handleChangeAudioEffects = (value: boolean) => {
    room.scene.audio.toggle(value);
  };

  return (
    <div className={styles.grid}>
      <ParamSelect
        type={SettingsType.Language}
        onChange={handleChangeLanguage}
        defaultValue={language}
        options={Object.values(LanguageType) as LanguageType[]}
      />
      <ParamBoolean
        type={SettingsType.AudioEffects}
        onChange={handleChangeAudioEffects}
        defaultValue={Settings.getBoolean(SettingsType.AudioEffects, true)}
      />
      {document.fullscreenEnabled && (
        <ParamBoolean
          type={SettingsType.Fullscreen}
          onChange={handleChangeFullscreen}
          defaultValue={room.scene.isFullscreen()}
          store={false}
        />
      )}
      <ParamEnum
        type={SettingsType.Resolution}
        onChange={handleChangeResolution}
        defaultValue={room.scene.resolution}
        options={Object.values(SceneResolution) as SceneResolution[]}
      />
      <ParamInteger
        type={SettingsType.FpsLimit}
        onChange={handleChangeFpsLimit}
        defaultValue={room.scene.fpsLimit}
        options={[30, 45, 60]}
      />
    </div>
  );
};
