import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { InventoryItem } from './item';
import { Purchase } from './purchase';
import { InventoryPurchaseState } from './purchase/state';
import { SlotSelect } from './slot-select';
import { InventorySlotSelectState } from './slot-select/state';
import { INVENTORY_CATEGORY_ITEMS } from '../../const';
import { useInventoryItems } from '../../hooks/use-inventory-items';

import type { SkillVariant } from '~/shared/battle/entity/unit/player/skill/types';

import { Scrollbar } from '~/client/core/ui/components/scrollbar';
import { Section } from '~/client/core/ui/components/section';
import { InventoryItemType } from '~/shared/core/inventory/item/types';

import styles from './styles.module.scss';

export const InventoryTabSkill: React.FC = () => {
  const [purchase, setPurchase] = useRecoilState(InventoryPurchaseState);
  const [slotSelect, setSlotSelect] = useRecoilState(InventorySlotSelectState);

  const currentItems = useInventoryItems();

  const items = INVENTORY_CATEGORY_ITEMS[InventoryItemType.Skill].map((item) => ({
    item,
    have: currentItems.has(item),
  }));

  const activeItems = items.filter(({ have }) => have);
  const otherItems = items.filter(({ have }) => !have);

  useEffect(() => {
    return () => {
      setPurchase(null);
      setSlotSelect(null);
    };
  }, []);

  return purchase ? (
    <Purchase />
  ) : (slotSelect ? (
    <SlotSelect />
  ) : (
    <Scrollbar>
      <Section direction='vertical'>
        <div className={styles.grid}>
          {activeItems.map(({ item }) => (
            <InventoryItem key={item} item={item as SkillVariant} have />
          ))}
        </div>
        {otherItems.length > 0 && (
          <div className={styles.grid}>
            {otherItems.map(({ item }) => (
              <InventoryItem key={item} item={item as SkillVariant} />
            ))}
          </div>
        )}
      </Section>
    </Scrollbar>
  ));
};
