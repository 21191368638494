import React from 'react';

import { Button } from '..';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';

import IconBack from './icons/back.svg';

type Props = {
  onClick: VoidFunction;
  size?: 'small' | 'medium' | 'large';
};

export const ButtonBack: React.FC<Props> = ({ onClick, size }) => {
  const language = useLanguage();

  return (
    <Button icon={IconBack} onClick={onClick} size={size} view='dark'>
      {language('Back')}
    </Button>
  );
};

