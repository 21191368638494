import { Battle } from './battle';
import { Assets } from './core/assets';
import { Client } from './core/client';
import { Device } from './core/device';
import { ErrorListener } from './core/error-listener';
import { Input } from './core/input';
import { Language } from './core/language';
import { SDK } from './core/sdk';
import { Version } from './core/version';
import { Relay } from './relay';

import { RoomType } from '~/shared/core/room/types';

(async () => {
  ErrorListener.listen();

  Client.lookAuthPreset();

  const language = Language.getType();
  Language.setDocumentTag(language);

  const support = Device.checkSupports();
  if (!support) {
    return;
  }

  const valid = await Version.checkClientVersion();
  if (!valid) {
    return;
  }

  Device.addPlatformLabel();
  Device.addListeners();

  Client.setLoading('Loading resources');

  await Promise.all([
    SDK.load(),
    Assets.load((progress) => {
      Client.setLoading(`Loading resources\n\n${Math.ceil(progress * 100)}%`);
    }),
  ]);

  Client.defineRoom(RoomType.Relay, Relay);
  Client.defineRoom(RoomType.Battle, Battle);

  Client.connect();
  Client.findAndJoinRoom();

  Input.addListeners();

  SDK.completeLoading();
})();
