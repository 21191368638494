import React, { useState } from 'react';

import { AuthLogIn } from './login';
import { AuthSingUp } from './signup';
import { AuthTab } from './types';

import { Client } from '~/client/core/client';
import { Device } from '~/client/core/device';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRequest } from '~/client/core/request/ui/hooks/use-request';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Close } from '~/client/core/ui/components/close';
import { Section } from '~/client/core/ui/components/section';
import { Tabs } from '~/client/core/ui/components/tabs';
import { useEvent } from '~/client/core/ui/hooks/use-event';
import { UserAuthRequest } from '~/shared/core/user/auth/types';

import styles from './styles.module.scss';

type Props = {
  onClose?: VoidFunction;
};

export const AuthForm: React.FC<Props> = ({ onClose }) => {
  const room = useRoom();
  const language = useLanguage();

  const setToken = useRequest(UserAuthRequest.SetToken);

  const [tab, setTab] = useState(AuthTab.SignUp);

  const tabContent = tab === AuthTab.LogIn
    ? <AuthLogIn />
    : <AuthSingUp />;

  const tabs = Object.values(AuthTab).map((type) => ({
    key: type,
    label: language(type),
  }));

  useEvent(Device.onScreenRelease, () => {
    const token = Client.getAuthToken();
    if (token) {
      setToken.fetch({ token })
        .then(() => {
          room.refreshUI();
        })
        .catch(() => {});
    }
  }, []);

  return (
    <Section direction="vertical" gap={32} align='center'>
      <Section direction="vertical" gap={48} className={styles.container}>
        <Tabs
          tabs={tabs}
          active={tab}
          onChange={(tab) => setTab(tab as AuthTab)}
        />
        {tabContent}
      </Section>
      {onClose && (
        <Close onClick={onClose} fixed />
      )}
    </Section>
  );
};
