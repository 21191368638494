import { useState } from 'react';

export function useStateMap<K, V>(defaultValue?: Map<K, V>) {
  const [map, setMap] = useState(() => (
    new Map<K, V>(defaultValue)
  ));

  const clone = () => new Map(map);

  return {
    get(key: K) {
      return map.get(key);
    },

    has(key: K) {
      return map.has(key);
    },

    set(key: K, value: V) {
      const newMap = clone();
      newMap.set(key, value);
      setMap(newMap);
    },

    delete(key: K) {
      const newMap = clone();
      newMap.delete(key);
      setMap(newMap);
    },

    clear() {
      setMap(new Map());
    },

    forEach(callback: (key: K, value: V) => void) {
      return Array.from(map.entries())
        .forEach(([key, value]) => {
          callback(key, value);
        });
    },

    map(callback: (key: K, value: V) => any) {
      return Array.from(map.entries())
        .map(([key, value]) => callback(key, value));
    },
  };
}
